import React from 'react';
import ParkingIconContainer from '../../../features/ParkingDiscoveryContainer/ParkingIconContainer/ParkingIconContainer';
import { 
  ListItem, 
  AvatarContainer, 
  InfoContainer,
  CostLabel,
} from './ParkingListItem.style';


const ParkingListItem = (props) => {

  const renderFooter = () => {

    if (props.mobilePopup) {
      return (
        <button className="btn btn-primary btn-block">{props.translations.view_details_and_book}</button>
      );
    }

    return (
      <React.Fragment>
        {
          props.parkingSpace.instant_booking ? (
            <span className="heading-font text-uppercase bolt" style={{fontSize: '0.8em'}}>
              {props.translations.instant_booking} 
              <i style={{marginLeft: '0.4em'}} className="fa fa-bolt" />
            </span>
          ) : (
            <span className="heading-font text-uppercase" style={{ fontSize: '0.8em'}}>{props.translations.request_to_book}</span>
          )
        }
        <CostLabel
          className="text-capitalize" 
          active={
            props.selectedParkingSpace && 
            props.selectedParkingSpace.id === props.parkingSpace.id
          }
          
          available={props.parkingSpace.available}
        >
          {props.parkingSpace.available ? props.parkingSpace.displayPrice : props.translations.sold}
        </CostLabel>
      </React.Fragment>
    );
  } // end renderFooter

  const renderIcons = () => {
    const ICONS_TO_DISPLAY = 4;

    if (props.parkingSpace.facility_ids.length <= ICONS_TO_DISPLAY) {
      return props.parkingSpace.facility_ids.map((fID) => (
        <ParkingIconContainer key={fID} id={fID} />
      ));
    } // if
    
    let iconElements = [];
    for (let i = 0; i < ICONS_TO_DISPLAY; i++) {
      const id = props.parkingSpace.facility_ids[i];
      iconElements.push(<ParkingIconContainer key={id} id={id} />)
    }
    
    iconElements.push(<span key={-1} style={{fontSize: '0.8em', fontWeight: '400'}}>+{props.parkingSpace.facility_ids.length - ICONS_TO_DISPLAY}</span>);

    return iconElements;
  } // end renderIcons


  return (
    <ListItem 
      onClick={() => props.handleClick(props.parkingSpace)}
      activeItem={
        props.selectedParkingSpace && 
        props.selectedParkingSpace.id === props.parkingSpace.id
      }
    >
      <AvatarContainer>
        <img src={props.parkingSpace.featured_image_preview_url} />
      </AvatarContainer>
      <InfoContainer>
        <div className='row'>
          <div className={props.mobilePopup ? 'col-7' : 'col-md-9'}>
            <div>
              <span>{props.parkingSpace.name}</span>
            </div>

            <div className='pt-2'>
              <h6>{props.parkingSpace.street_address}</h6>
            </div>
          </div>
          <div className={props.mobilePopup ? 'col-5 justify-content-end d-flex' : 'col-md-3 justify-content-end d-flex'}>
            {renderIcons()}
          </div>
        </div>
        <div style={{display: 'flex', 'justifyContent': 'space-between', 'alignItems': 'flex-end'}}>
          {renderFooter()}
        </div>
      </InfoContainer>
    </ListItem>
  )
};

export default ParkingListItem;