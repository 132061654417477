import { SET_SELECTED_PARKING_SPACE } from '../../actionTypes';
import axios from 'axios';

export const setSelectedParkingSpace = (
  selectedParkingSpace = null,
) => {
  if (selectedParkingSpace) {
    dataLayer.push({
      'event': 'productClick',
      'ecommerce': {
        'click': {
          'actionField': {'list': 'Map Click'},      // Optional list property.
          'products': [{
            'id': selectedParkingSpace.id,
            'price': selectedParkingSpace.displayPrice,
          }]
        }
      }
    });
  } // end if


  return {
    type: SET_SELECTED_PARKING_SPACE,
    payload: selectedParkingSpace,
  };
}