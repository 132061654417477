import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  width: 50px;  

  background: transparent;
  border: 0;
  border-left: 1px solid HSLA(210, 7%, 95%, 1.00);

  outline: none;

  & i {
    font-size: 1.4em;
  }
`;