import React, { Component } from 'react';
import { 
  Wrapper,
  HeaderWrapper,
  SidenavWrapper,
  MainWrapper,
  MapWrapper,
} from './ParkingDiscoveryWrapper.style';

class ParkingDiscoveryWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
      hideSidenav: false,
    };
  }


  render() {
    const { 
      children: { 
        header, 
        sidenav, 
        map,
      } 
    } = this.props;

    return (
      <Wrapper>
        <HeaderWrapper>
          {header}
        </HeaderWrapper>
        <MainWrapper mobile={this.state.isMobile}>
          <SidenavWrapper 
            mobileActive={this.props.isMobile && this.props.mobileListViewActive}
          >
            {sidenav}
          </SidenavWrapper>
          <MapWrapper onClick={this.props.onMapClick}>
            {map}
          </MapWrapper>
        </MainWrapper>
      </Wrapper>
    );
  }
}

export default ParkingDiscoveryWrapper;