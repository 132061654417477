import React, { Component } from 'react';
import Lightbox from 'react-images';
import axios from 'axios';
import {
  PopupWrapper,
  ScrollY,
  Header,
  IconsWrapper,
  StreetViewWrapper,
  ImagePreviews,
} from './ParkingDetailPopup.style';
import ParkingIconContainer from '../../ParkingIconContainer/ParkingIconContainer';

class ParkingDetailPopup extends Component {
  constructor(props) {
    super(props);
    this.streetViewRef = React.createRef();
    this.streetView = null;

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      activeStreetView: false,
      showTruncatedSummary: true,
      lister: {
        loaded: false,
      },
    };
  } // end constructor

  componentDidMount() {
    // if (this.props.activePopup)
    //   this.setupStreetView();

    if (this.props.activePopup) this.fetchListerInfo();
  } // end componentDidMount

  componentDidUpdate(prevProps) {
    if (
      this.props.activePopup &&
      prevProps.activePopup !== this.props.activePopup
    ) {
      this.setState({
        activeStreetView: false,
        showTruncatedSummary: true,
        lister: { loaded: false },
      });
      this.fetchListerInfo();
    }
  } // end componentDidUpdate

  fetchListerInfo = () => {
    var requestPath = `/${this.props.activePopup.region.slug || ''}/parking/${
      this.props.activePopup.slug
    }.json`;

    axios
      .get(requestPath)
      .then(({ data: { lister = { name: null } } }) => {
        this.setState({
          lister: {
            loaded: true,
            name: lister.name,
            memberSince: lister.member_since,
            emailVerified: lister.email_verified,
            phoneVerified: lister.name.phone_verified,
            avatarUrl: lister.avatar_url,
            responseRate: lister.response_rate,
          },
        });
      })
      .catch(() => {
        this.setState({ lister: { loaded: false } });
      });
  }; // end fetchListerInfo

  showStreetView = () => {
    this.setState({ activeStreetView: true }, () => {
      this.setupStreetView();
    });
  }; // end showStreetView

  renderCheckoutHref = () => {
    return `/parking/${
      this.props.activePopup.slug
    }/bookings/new?start_time=${this.props.searchOptions.startDate.format(
      'YYYY-MM-DD'
    )} ${
      this.props.searchOptions.period === 'HOURLY'
        ? this.props.searchOptions.startTime.slice(0, 2)
        : '00'
    }:${
      this.props.searchOptions.period === 'HOURLY'
        ? this.props.searchOptions.startTime.slice(2)
        : '00'
    }:00&end_time=${
      this.props.searchOptions.period === 'HOURLY'
        ? this.props.searchOptions.endDate.format('YYYY-MM-DD')
        : this.props.searchOptions.startDate
            .clone()
            .add(31, 'days')
            .format('YYYY-MM-DD')
    } ${
      this.props.searchOptions.period === 'HOURLY'
        ? this.props.searchOptions.endTime.slice(0, 2)
        : '00'
    }:${
      this.props.searchOptions.period === 'HOURLY'
        ? this.props.searchOptions.endTime.slice(2)
        : '00'
    }:00
    `;
  };

  renderExternalGoogleMaps = () => {
    return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${
        this.props.activePopup.location[0]
        },${
          this.props.activePopup.location[1]
        }`;
  }

  renderTruncateElement = () => {
    if (!this.props.activePopup) return null;

    const MAX_CHAR_COUNT = 150;
    if (this.props.activePopup.summary.length < MAX_CHAR_COUNT) {
      return <p>{this.props.activePopup.summary}</p>;
    }

    if (this.state.showTruncatedSummary) {
      return (
        <React.Fragment>
          <p className="m-0">
            {this.props.activePopup.summary.slice(0, MAX_CHAR_COUNT)}...
          </p>
          <button
            style={{ background: 'transparent', outline: 'none' }}
            className="btn btn-clr p-l-0 text-primary text-capitalize"
            onClick={() => this.toggleTruncatedSummary(false)}
          >
            {this.props.translations.read_more}
          </button>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <p className="m-0">{this.props.activePopup.summary}</p>
          <button
            style={{ background: 'transparent', outline: 'none' }}
            className="btn btn-clr p-l-0 text-primary text-capitalize"
            onClick={() => this.toggleTruncatedSummary(true)}
          >
            {this.props.translations.show_less}
          </button>
        </React.Fragment>
      );
    }
  }; // end renderTruncateElement

  toggleTruncatedSummary = val => {
    this.setState({ showTruncatedSummary: val });
  };

  setupStreetView = () => {
    if (!this.streetViewRef.current) {
      return;
    }

    if (this.streetView) {
      this.streetView.setPosition({
        lat: this.props.activePopup.location[0],
        lng: this.props.activePopup.location[1],
      });
    } // end if

    this.streetView = new google.maps.StreetViewPanorama(
      this.streetViewRef.current,
      {
        position: {
          lat: this.props.activePopup.location[0],
          lng: this.props.activePopup.location[1],
        },
        pov: { heading: 165, pitch: 0 },
        zoom: 1,
        linksControl: false,
        panControl: false,
        addressControl: false,
        zoomControl: false,
        enableCloseButton: false,
        fullscreenControl: false,
      }
    );
  }; // end setupStreetView

  renderLightbox = () => {
    if (
      !this.props.activePopup.images ||
      (this.props.activePopup.images && !this.props.activePopup.images.length)
    ) {
      return null;
    } // end if

    return (
      <React.Fragment>
        <ImagePreviews
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          {this.props.activePopup.images.slice(0, 2).map(i => (
            <img
              key={i.id}
              className="mb-3"
              onClick={() => {
                this.setState({ lightboxIsOpen: true });
              }}
              src={i.image_styles.medium}
            />
          ))}
        </ImagePreviews>

        <Lightbox
          className="mb-3"
          images={this.props.activePopup.images.map(i => ({
            src: i.image_styles.preview,
          }))}
          isOpen={this.state.lightboxIsOpen}
          currentImage={this.state.currentImage}
          onClickPrev={() => {
            this.setState(({ currentImage }) => ({
              currentImage: currentImage - 1,
            }));
          }}
          onClickNext={() => {
            this.setState(({ currentImage }) => ({
              currentImage: currentImage + 1,
            }));
          }}
          onClose={() => {
            this.setState({ lightboxIsOpen: false });
          }}
        />
      </React.Fragment>
    );
  };

  renderComponent = () => {
    const { activePopup, handlePopupClose } = this.props;

    return (
      <PopupWrapper
        style={this.props.style}
        column
        wrap="nowrap"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        {!activePopup && null}

        {activePopup && (
          <React.Fragment>
            <div className="popup-arrow" />
            <section className="wrapper-container">
              <Header
                column
                wrap="nowrap"
                justifyContent="flex-start"
                alignItems="stretch"
              >
                <h5>
                  {activePopup.address_no_numbers}
                  <button
                    onClick={handlePopupClose}
                    className="btn btn-clr p-0 m-l-1"
                    style={{ background: 'transparent', outline: 'none' }}
                  >
                    <i className="fa fa-times" />
                  </button>
                </h5>

                {activePopup.instant_booking ? (
                  <span
                    className="heading-font text-uppercase bolt mb-3"
                    style={{ fontSize: '0.8em' }}
                  >
                    {this.props.translations.instant_booking}
                    <i style={{ marginLeft: '0.4em' }} className="fa fa-bolt" />
                  </span>
                ) : (
                  <span
                    className="heading-font text-uppercase mb-3"
                    style={{ fontSize: '0.8em' }}
                  >
                    {this.props.translations.request_to_book}
                  </span>
                )}

                <a
                  href={`/parking/${this.props.activePopup.slug}`}
                  target="_blank"
                  className="btn btn-primary text-capitalize mb-2"
                >
                  {this.props.translations.more_details}
                </a>

                <a
                  href={this.renderCheckoutHref()}
                  target="_blank"
                  className="btn btn-light text-capitalize"
                >
                  {activePopup.available
                    ? this.props.translations.proceed_to_checkout
                    : this.props.translations.check_availability}
                </a>
                {!activePopup.available && (
                  <div className="mt-1 text-center alert alert-warning m-b-0">
                    <p>
                      {this.props.translations.parking_space_not_available_text}
                    </p>
                  </div>
                )}
              </Header>
              <ScrollY flex={1}>
                <h5 className="mb-3 text-capitalize">
                  {this.props.translations.space_details}
                </h5>
                {this.renderTruncateElement()}

                <hr />
                {this.renderUserInfo()}

                <hr />

                {this.renderLightbox()}

                <React.Fragment>
                  <div
                    className="mb-3"
                  />
                  <a
                    href={this.renderExternalGoogleMaps()}
                    target="_blank"
                    className="btn btn-dark btn-large btn-block btn-black text-capitalize"
                    >
                    <i className="fas fa-street-view fa" />
                    <span className="text-capitalize h5 ml-2">
                      {this.props.translations.view_streetview}
                    </span>
                  </a>

                </React.Fragment>

                <IconsWrapper
                  className="mt-3"
                  wrap="wrap"
                  justifyContent="flex-start"
                  alignItems="stretch"
                >
                  {activePopup.facility_ids.map(fID => (
                    <div key={fID}>
                      <ParkingIconContainer
                        id={fID}
                        style={{
                          width: '35px',
                          height: '35px',
                          opacity: '0.8',
                        }}
                      />
                      <span className="mt-1">
                        {this.props.facilities[fID].name}
                      </span>
                    </div>
                  ))}
                </IconsWrapper>
              </ScrollY>
            </section>
          </React.Fragment>
        )}
      </PopupWrapper>
    );
  };

  renderUserInfo = () => {
    return (
      <React.Fragment>
        <div className={this.state.lister.loaded ? 'show' : 'hidden'}>
          <div className="row">
            <div className="col-4 offset-4">
              <img
                className="rounded-circle avatar"
                src={this.state.lister.avatarUrl}
              />
            </div>
          </div>

          <div className="row mt-3 text-center">
            <div className="col-12">
              <h5 className="user_name">{this.state.lister.name}</h5>

              <p className="member_since text-muted">
                {this.props.translations.member_since}{' '}
                {this.state.lister.memberSince}
              </p>

              <div className="row text-success d-none d-lg-block">
                {this.state.lister.emailVerified && (
                  <div className="col">
                    <i className="fas fa-check-circle icon-left" />
                    {this.props.translations.email_verified}
                  </div>
                )}

                {this.state.lister.phoneVerified && (
                  <div className="col">
                    <i className="fas fa-check-circle icon-left" />
                    {this.props.translations.phone_verified}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <a
              href={`/messages/new?id=${this.props.activePopup.slug}`}
              target="_blank"
              className="btn btn-outline-primary btn-block text-capitalize"
            >
              {this.props.translations.contact_lister}
            </a>
            {this.state.lister.loaded && this.state.lister.responseRate && (
              <div className="text-center mt-3 text-muted">
                <i className="icon-left fas fa-comment-alt" />
                <strong>{this.props.translations.response_rate}: </strong>
                {this.state.lister.responseRate}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return this.renderComponent();
  } // end render
} // end ParkingDetailPopup

export default ParkingDetailPopup;
