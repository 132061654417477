import React from 'react';
import ParkingListItem from './ParkingListItem/ParkingListItem';
import { List } from './ParkingList.style';
import ParkingListNotFound from '../../features/ParkingDiscoveryContainer/ParkingListContainer/ParkingListNotFound'

const ParkingList = ({ 
  data, 
  setSelectedParkingSpace, 
  selectedParkingSpace,
  translations,
  authentication_token
}) => {
  return (
    <List >
      {
        data.map((p) => {
          return (
            <ParkingListItem 
              key={p.id} 
              parkingSpace={p} 
              handleClick={setSelectedParkingSpace}
              selectedParkingSpace={selectedParkingSpace}
              translations={translations}
            />
          );
        })
      }
      {
        data.length <= 4 && <ParkingListNotFound authentication_token={authentication_token}/>
      }
    </List>
  )
};

export default ParkingList;