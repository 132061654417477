import { 
  SET_SEARCH_CONFIG_SEARCH_BAR, 
  INITILIZE_FILTERS,
  SET_PARKING_FILTER_FACILITIES,
  SET_PARKING_FILTER_PROPERTY_TYPES,
  SET_PARKING_FILTER_INSTANT_BOOKING,
  SET_SEARCH_MAP_CENTER,
  SET_SEARCH_TYPE,
  SET_SEARCH_MAP_FURTHEST_POINT,
  SET_SEARCH_CURRENT_RADIUS,
  SET_AVAILABILITY_CANCEL_REF,
  SET_SEARCH_LOCATION,
} from '../../actionTypes';

export const setSearchConfigSearchBar = (payload) => ({
  type: SET_SEARCH_CONFIG_SEARCH_BAR,
  payload,
});

export const setLocation = (payload) => ({
  type: SET_SEARCH_LOCATION,
  payload,
});


export const initilizeFilters = ({ facilities, propertyTypes }) => {
  // derive from [{ ... }, { ... }] => { [id]: { ... }, ... }
  const facilitiesObj = facilities.reduce((obj, currentValue) => {
    obj[currentValue.id] = {
      ...currentValue,
      active: false,
    };

    return obj;
  }, {});

  // derive from [{ ... }, { ... }] => { [id]: { ... }, ... }
  const propertyTypesObj = propertyTypes.reduce((obj, currentValue) => {
    obj.data[currentValue.id] = currentValue;
    return obj;
  }, {data: {}, activePropertyType: {} });

  return {
    type: INITILIZE_FILTERS,
    payload: {
      facilities: facilitiesObj,
      propertyTypes: propertyTypesObj,
    },
  };
};

export const setFilter = ({ filterType, filterKey, value }) => ({
  type: SET_PARKING_FILTER_FACILITIES,
  payload: {
    filterType,
    filterKey,
    value,
  },
});

export const setInstantBookingFilter = (payload) => ({
  type: SET_PARKING_FILTER_INSTANT_BOOKING,
  payload,
});

export const setPropertyType = (payload) => ({
  type: SET_PARKING_FILTER_PROPERTY_TYPES,
  payload,
});

export const setSearchMapCenter = (payload) => {
  return {
    type: SET_SEARCH_MAP_CENTER,
    payload,
  };
};

// the type of search, FILTER_CHANGE, BOUNDS_CHANGE, LOCATION_CHANGE
export const setSearchType = (payload) => ({
  type: SET_SEARCH_TYPE,
  payload,
});


export const setMapFurthestPoint = (payload) => ({
  type: SET_SEARCH_MAP_FURTHEST_POINT,
  payload,
});

export const setCurrentRadius = (payload) => ({
  type: SET_SEARCH_CURRENT_RADIUS,
  payload,
});

export const setAvailabilityCancelRef = (payload) => ({
  type: SET_AVAILABILITY_CANCEL_REF,
  payload,
});



