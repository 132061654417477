import styled, { css } from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;

  ${flexDirection}

  ${flexWrap}

  ${justifyContent}

  ${alignItems}
`;

export const FlexItem = styled.div`
  ${flex}
`;

function flexDirection({ column }) {
  if (column) {
    return css`
      flex-direction: ${column ? 'column' : 'row'};
    `;
  }

  return '';
} // end flexDirection

function justifyContent({ justifyContent }) {
  if (justifyContent) {
    return css`
      justify-content: ${justifyContent};
    `;
  }
} // end justifyContent

function alignItems({ alignItems }) {
  if (alignItems) {
    return css`
      align-items: ${alignItems};
    `;
  }
} // end alignItems

function flex({ flex }) {
  if (flex) {
    return css`
      flex: ${flex}
    `;
  }
};

function flexWrap({ wrap }) {
  if (wrap) {
    return css`
      flex-wrap: ${wrap};
    `;
  }
} // end flexWrap