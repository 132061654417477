export const UPDATE_PARKING_LOADER = 'UPDATE_PARKING_LOADER';

export const SET_ALGOLIA = 'SET_ALGOLIA';

export const SET_AVAILABLE_PARKING_SPACES = 'SET_AVAILABLE_PARKING_SPACES';

export const NEW_PARKING_SEARCH_RESULTS = 'NEW_PARKING_SEARCH_RESULTS';

export const UPDATE_SORT_FIELD = 'UPDATE_SORT_FIELD';

export const SET_SELECTED_PARKING_SPACE = 'SET_SELECTED_PARKING_SPACE';

export const SET_SEARCH_CONFIG_SEARCH_BAR = 'SET_SEARCH_CONFIG_SEARCH_BAR';

export const TOGGLE_SEARCH_FILTERS = 'TOGGLE_SEARCH_FILTERS';

export const ON_MOBILE_CHANGE = 'ON_MOBILE_CHANGE';

export const MOBILE_LIST_VIEW_CHANGE = 'MOBILE_LIST_VIEW_CHANGE';

export const INITILIZE_FILTERS = 'INITILIZE_FILTERS';

export const SET_PARKING_FILTER_FACILITIES = 'SET_PARKING_FILTER_FACILITIES';

export const SET_PARKING_FILTER_INSTANT_BOOKING = 'SET_PARKING_FILTER_INSTANT_BOOKING';

export const SET_PARKING_FILTER_PROPERTY_TYPES = 'SET_PARKING_FILTER_PROPERTY_TYPES';

export const SET_SEARCH_MAP_CENTER = 'SET_SEARCH_MAP_CENTER';

export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';

export const SET_SEARCH_MAP_FURTHEST_POINT = 'SET_SEARCH_MAP_FURTHEST_POINT';

export const SET_SEARCH_CURRENT_RADIUS = 'SET_SEARCH_CURRENT_RADIUS';

export const SET_AVAILABILITY_CANCEL_REF = 'SET_AVAILABILITY_CANCEL_REF';

export const SET_SEARCH_LOCATION = 'SET_SEARCH_LOCATION';

export const SET_FAVORITE_MODAL= 'SET_FAVORITE_MODAL';