import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  & > * {
    background-color: white;
    padding: 12px;
    height: 64px;
    border-radius: 32px;
    box-shadow: 2px 4px 8px 0 rgba(0,45,91,.1);

    & > .period-container {
      align-items: center;
      /* margin-bottom: 6px; */

      & > label {
        border-bottom-style: hidden;
        padding-bottom: 0;
        padding: 0 1.4em;
      }

      & > label:first-child {
        border-right: 1px solid HSLA(208, 21%, 84%, 1.00);
      }

    }

    & > .search-container {
      input {
        background-color: transparent;
        border: 0;
        padding-left: 2.2em;
      }
    }

    & > .submit-container {
      margin-left: 2em;
    }
  }

  @media (max-width: 992px) {
    ${mobileStyles()}
  }
`;

function mobileStyles() {
  return css`
    width: 100%;

    & > * {
      flex-direction: column;
      justify-content: start;
      align-items: stretch;
      background-color: white;
      padding-bottom: 0px;

      height: auto;
      border-radius: 7px;

      .period-container {
        padding: 1em 0;
        padding-top: 0;
        margin: 0;
        margin-bottom: 8px;

        & > label {
          flex: 1;
          padding: 0.5em;
          display: block;
          text-align: center !important;
          border-bottom-style: solid;
        }

        & > label:first-child {
          border-right: 0;
        }

      }

      .search-container {
        flex: initial;
        margin-bottom: 1em;

        & input {
          padding: 0.8em;
          padding-left: 2em;
          background-color: #F2F2F2;
        }
      }

      .dates-container {
        margin: 0;
        margin-bottom: 1em;

        & > div {
          flex: 1;
        }

        .SingleDatePickerInput_calendarIcon {
          display: none;
        }
      }

      .submit-container {
        padding: 0.8em;
        font-weight: 0.8em;
        margin-bottom: 1em;
        margin-left: 0;
      }
    }
  `;
}
