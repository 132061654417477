import { 
  TOGGLE_SEARCH_FILTERS, 
  ON_MOBILE_CHANGE,
  MOBILE_LIST_VIEW_CHANGE,
} from '../../actionTypes';

const initialState = {
  activeSearchFilters: false,
  isMobile: false, 
  mobileListViewActive: false, 
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SEARCH_FILTERS:
      return { ...state, activeSearchFilters: action.payload };
    case ON_MOBILE_CHANGE:
      return { ...state, isMobile: action.payload };
    case MOBILE_LIST_VIEW_CHANGE:
      return { ...state, mobileListViewActive: action.payload };
    default:
      return state;
  }
};

export default reducer;