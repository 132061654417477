import React, { Component } from 'react';
import { connect } from 'react-redux';

import ParkingDiscovery from './ParkingDiscovery';
import { setSelectedParkingSpace } from '../../redux/parkingDiscoveryStore/selectedParkingSpace/actions'
import { getSearchResults, boundarySearch } from '../../redux/parkingDiscoveryStore/searchResults/operations';
import { setSearchConfigSearchBar, setSearchType } from '../../redux/parkingDiscoveryStore/searchConfig/actions';
import { onMobileChange } from '../../redux/parkingDiscoveryStore/uiFlags/actions';

class ParkingDiscoveryContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeParkingDetailPopup: null
    };

    const url = new URL(window.location.href);
    this.originAndPath = url.origin + url.pathname
  } // end constructor

  handleParkingSpaceSelection = (parkingSpace) => {
    this.setState({ activeParkingDetailPopup: parkingSpace });
  };
  
  handleSearchBarChange = (searchBarState) => {
    if (searchBarState.type === 'period' && this.props.selectedParkingSpace) {
      this.props.setSelectedParkingSpace(null);
    }

    const newSearchBarConfig = { 
      location: { ...searchBarState.payload.location },
      period: searchBarState.payload.period,
      ...searchBarState.payload.dates, 
    };
    
    this.props.setSearchConfigSearchBar(newSearchBarConfig);

    // do search
    if (
      !this.props.isMobile &&
      searchBarState.type === 'LOCATION_INIT'
      ) {
      this.props.getSearchResults();
    } else if (
      this.props.searchConfig.map.center && 
      this.props.searchConfig.map.furthestPoint &&
      searchBarState.type !== 'location'
    ) {
      this.props.boundarySearch();
    }


    // update the url
    if (
      (
        searchBarState.type === 'location' || 
        searchBarState.type === 'LOCATION_INIT' 
      ) &&
      searchBarState.payload.location.lat &&
      searchBarState.payload.location.lng 
    ) {
      this.updateUrl(searchBarState)
    }
  } // end handleSearchBarChange

  updateUrl = (searchBarState) => {
    var currentUrl = `${this.originAndPath}?lat=${searchBarState.payload.location.lat}&lng=${searchBarState.payload.location.lng}&address=${searchBarState.payload.location.address}&period=${searchBarState.payload.period || 'HOURLY'}`;
    history.pushState(null, null, currentUrl);
  } // end updateUrl

  handleSearchBarSubmit = (searchBarState) => {
    // don't submit if not lat & lng.
    if (
      !this.props.searchConfig.searchBar.location.lat &&
      !this.props.searchConfig.searchBar.location.lng
    ) {
      return;
    }

    // set the search type
    this.props.setSearchType('LOCATION_CHANGE')
    // search by location
    this.props.getSearchResults();
  } // end handleSearchBarSubmit

  onMapClick = (e) => {
    if (this.props.isMobile || markerClick()) return;
      
    if (this.props.selectedParkingSpace) {
      this.props.setSelectedParkingSpace(null);
    }

    function markerClick() {
      const itemClickedOn = e.target
      const classesToCheck = [
        'inner', 'bolt', 'parking_space_tooltip', 
        'availability-true', 'availability-false', 
        'map_marker', 'parking_icon',
      ];

      return !!classesToCheck.find(c => (
        itemClickedOn.classList.contains(c)
      ));
    } // end contains
  } // end on mapClick

  render() {
    return (
      <ParkingDiscovery 
        onMapClick={this.onMapClick}
        selectedParkingSpace={this.props.selectedParkingSpace}
        setSelectedParkingSpace={this.props.setSelectedParkingSpace}
        searchResults={this.props.searchResults}
        onSearchBarChange={this.handleSearchBarChange}
        onSearchBarSubmit={this.handleSearchBarSubmit}
        searchBarDates={this.props.searchBarDates}
        onMobileChange={this.props.onMobileChange}
        isMobile={this.props.isMobile}
        authentication_token={this.props.authentication_token}
        mobileListViewActive={this.props.mobileListViewActive}
        location={this.props.searchConfig.searchBar.location}
        period={this.props.searchConfig.searchBar.period}
        translations={this.props.translations}
      />
    );
  }
}

const mapStateToProps = ({ 
  searchConfig,
  searchResults, 
  selectedParkingSpace, 
  uiFlags: { isMobile, mobileListViewActive },
  translations,
}) => {
  return {
    selectedParkingSpace,
    searchResults,
    isMobile,
    mobileListViewActive,
    searchConfig,
    translations,
  };
}; // end mapStateToProps

export default connect(mapStateToProps, {
  getSearchResults,
  setSelectedParkingSpace,
  setSearchConfigSearchBar,
  setSearchType,
  onMobileChange,
  boundarySearch,
})(ParkingDiscoveryContainer);