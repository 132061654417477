import React from 'react';
import { Transition } from 'react-spring/renderprops';
import ParkingListItem from '../../../../components/ParkingList/ParkingListItem/ParkingListItem';
import { Wrapper, PopupWrapper } from './ParkingDetailPopupMobile.style.js';
import ParkingDetailPopup from '../../ParkingDetailPopupContainer/ParkingDetailPopup/ParkingDetailPopup';

const ParkingDeatilPopupMobile = ({ 
  parkingSpace, 
  showDetailsPopup, 
  onClick, 
  onPopupClose, 
  facilities,
  translations,
  searchOptions,
}) => {
  return (
    <React.Fragment>
      {
        !showDetailsPopup &&
        <Wrapper>
          <ParkingListItem 
            mobilePopup 
            parkingSpace={parkingSpace} 
            handleClick={onClick} 
            translations={translations} 
          />
        </Wrapper>
      }

      {
        showDetailsPopup &&
        <PopupWrapper>
          <Transition
            items={!!parkingSpace}
            from={{ position: 'absolute', opacity: 0, left: '-100%' }}
            enter={{ opacity: 1, left: '0' }}
            leave={{ opacity: 0, left: '-100%' }}
          >
            {
              toggle => toggle && ((style) => (
                <ParkingDetailPopup
                  style={style}
                  activePopup={parkingSpace} 
                  handlePopupClose={onPopupClose} 
                  facilities={facilities}
                  translations={translations}
                  searchOptions={searchOptions}
                />
              ))
            }
          </Transition>
        </PopupWrapper>
      }
    </React.Fragment>
    
    
  );
};

export default ParkingDeatilPopupMobile;

// activePopup, handlePopupClose