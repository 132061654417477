import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mobileListViewChange } from '../../../redux/parkingDiscoveryStore/uiFlags/actions';
import { setSelectedParkingSpace } from '../../../redux/parkingDiscoveryStore/selectedParkingSpace/actions';
import ParkingListViewSwitcher from './ParkingListViewSwitcher/ParkingListViewSwitcher';

class ParkingListViewSwitcherContainer extends Component {
  handleChange = () => {
    this.props.mobileListViewChange(!this.props.mobileListViewActive)
    this.props.setSelectedParkingSpace(null);
  } // end handleChange

  render() {
    return (
      <ParkingListViewSwitcher 
        onChange={this.handleChange}
        activeListView={this.props.mobileListViewActive}
      />
    )
  }
}

const mapStateToProps = ({ uiFlags: { mobileListViewActive }}) => ({
  mobileListViewActive
});

export default connect(mapStateToProps, { 
  mobileListViewChange,
  setSelectedParkingSpace,
})(ParkingListViewSwitcherContainer);