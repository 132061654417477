import styled from 'styled-components';
import { FlexContainer } from '../../../../styledComponents/flex';

export const Wrapper = styled(FlexContainer)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  width: 95%;
  max-width: 450px;
  margin-top: 20px;
  border-radius: 5px;


  box-shadow: 1px 1px 2px #D9D9D9;
  display: none;



  & > div:first-child {
    padding: 1em 1em;
    color: #027AFF;
    border-bottom: 1px solid HSLA(0, 0%, 96%, 1.00);
    cursor: text;
    font-weight: bold;


    i {
      margin-right: 1em;
      color: HSLA(0, 0%, 29%, 1.00);
    }

    span {
      overflow: hidden;
    }
  }

  & > div:last-child {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;

    padding: 0.5em;

    cursor: pointer;
    & > * {
      flex: 1;

      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: stretch;
      text-align: center;

      &:first-child {
        border-right: 1px solid #F5F5F5;
      }


      & > *:first-child {
        color: #333;
      }

      & > *:last-child {
        font-weight: bold;
        color: #027AFF;
      }
    }
  }

  @media (max-width: 992px) {
    display: flex;
  }
`;
