import styled from 'styled-components';

export const List = styled.section`
  flex: 1;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 0;
  background-color: white;
  list-style: none;
`;

