import styled from 'styled-components';
import { FlexContainer, FlexItem } from '../../styledComponents/flex';

export const SearchBarWrapper = styled(FlexContainer)``;

/*
--------------------------------------------------------------
PERIOD BUTTONS STYLE
--------------------------------------------------------------
*/
export const PeriodButtons = styled(FlexContainer)`
  margin-right: 0.5em;
`;

export const PeriodRadioButton = styled.label`
  display: flex;
  align-items: center;

  margin: 0;

  border-bottom: ${({ active }) => active ? '2px solid #157EFB' : '0'};

  padding-left: 10px;
  padding-right: 10px;

  color: ${({ active }) => active ? '#027AFF' : '#4D4D4D'};
  font-weight: ${({ active }) => active ? 'bold' : 'normal'};

  cursor: pointer;

  input[type=radio] {
    opacity: 0;
    position: absolute;
    left: -9999px;
  }
`;

/*
--------------------------------------------------------------
SEARCH INPUT STYLE
--------------------------------------------------------------
*/
export const SearchInputWrapper = styled(FlexItem)`
  position: relative;

  & i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: .7em;
    color: HSLA(213, 97%, 53%, 1.00);
  }

  input {
    border-radius: 5px;
    border: 1px solid #CAD4DE;

    padding-left: 2em;

    width: 100%;
    height: 100%;

    background-color: #F9F9F9;
    color: #545454;
    outline: none;
  }
`;

/*
--------------------------------------------------------------
DATES STYLE
--------------------------------------------------------------
*/
export const DatesWrapper = styled(FlexContainer)`
  margin-left: 2em;
  margin-right: 2em;

  & span {
    display: flex;
    align-items: center;

    margin-left: 10px;
    margin-right: 10px;
  }

  & .SingleDatePicker {
    border-radius: 5px;
    min-width: 170px;

    @media (max-width: 992px) {
      min-width: initial;
    }

    .SingleDatePickerInput {
      border-radius: 5px;
      border: 1px solid #CAD4DE;
      .DateInput {
        border-radius: 5px;
        .DateInput_input {
          border-radius: 5px;
          text-align: center;
          background-color: #F9F9F9;
          color: #545454;
          outline: none;
          padding-left: 1em;
        }

        .DateInput_input__focused {
          border-bottom: 0;
        }
      }
    }

    .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
      background: HSLA(213, 97%, 53%, 1.00);
      border: 1px double HSLA(213, 97%, 53%, 1.00);
      color: #fff;
    }

    .CalendarDay {
      outline: none !important;
    }

    .SingleDatePickerInput_calendarIcon {
      position: absolute;
      z-index: 1;
      transform: scale(0.8) translateY(-50%);
      transform-origin: 0 0;
      top: 50%;
      left: 0;
      margin-top: -2px;

      svg {
        fill: HSLA(213, 97%, 53%, 1.00);
      }
    }
  }
`;

export const ActiveDatesContainer = styled(FlexContainer)`
  & > * {
    margin: 1em 1.5em;
    margin-top: 0;
  }
`;

/*
--------------------------------------------------------------
SUBMIT BUTTON STYLE
--------------------------------------------------------------
*/
export const SubmitButtonWrapper = styled.button`
  border-radius: 25px;
  padding: 0 6em;
  margin-right: 2px;
  outline: none !important;
`;
