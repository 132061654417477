import React, { Component } from 'react';
import { connect } from 'react-redux';
import ParkingMobileSearchButton from './ParkingMobileSearchButton/ParkingMobileSearchButton';
import ParkingMobileSearchBar from './ParkingMobileSearchBar/ParkingMobileSearchBar';


class ParkingMobileSearchButtonContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acitveSearchBar: false,
    };
  }

  handleClick = () => {
    this.setState({ acitveSearchBar: true });
  } // end handleClick

  handleSearchBarClose = () => {
    this.setState({ acitveSearchBar: false });
  }

  render() {
    const dates = {
      startDate: this.props.searchBar.startDate || this.props.dates.startDate,
      endDate: this.props.searchBar.endDate || this.props.dates.endDate,
      startTime: this.props.searchBar.startTime || this.props.dates.startTime,
      endTime: this.props.searchBar.endTime || this.props.dates.endTime,
    };

    const location = {
      address: this.props.searchBar.location.address || '',
      lat: this.props.searchBar.location.lat || 0,
      lng: this.props.searchBar.location.lng || 0,
    };

    return (
      <React.Fragment>
        <ParkingMobileSearchButton 
          searchConfig={this.props.searchBar}
          onClick={this.handleClick} 
          translations={this.props.translations}
        />

        {
          this.state.acitveSearchBar &&
          <ParkingMobileSearchBar 
            translations={this.props.translations}
            onClose={this.handleSearchBarClose} 
            period={this.props.searchBar.period}
            location={location}
            dates={dates}
            onChange={this.props.onChange} 
            onSubmit={(value) => {
              this.props.onSubmit(value);
              this.handleSearchBarClose();
            }}
          />
        }
      </React.Fragment>
      
    );
  }
}

const mapStateToProps = ({ searchConfig: { searchBar }, translations }) => ({
  searchBar,
  translations,
});
export default connect(mapStateToProps)(ParkingMobileSearchButtonContainer);