import axios from 'axios';
import api from '../../../../api';
import { setResults } from '../actions';
import { setSelectedParkingSpace } from '../../selectedParkingSpace/actions';
import { toggleSearchFilters } from '../../uiFlags/actions';
import { setCurrentRadius, setSearchType } from '../../searchConfig/actions';


export const boundarySearch = () => {
  return (dispatch, getState) => {
    // get current store state
    const storeState = getState();
    // get the searchConfgi state from the store.
    const { searchConfig } = storeState;
    
    const algoliaHelper = api.algolia.algoliaHelper();

    // add numeric refinments: facilityIds and propertyTypes.
    addNumericRefinement(algoliaHelper, storeState);

    let config = {};
    config.aroundLatLng = `${searchConfig.map.center.lat}, ${searchConfig.map.center.lng}`;
    config.aroundRadius = mapRadius(
      searchConfig.map.center.lat, 
      searchConfig.map.center.lng,
      searchConfig.map.furthestPoint.lat,
      searchConfig.map.furthestPoint.lng,
    );
      
    // set the current radius
    dispatch(setCurrentRadius(config.aroundRadius));

    // search algolia with the above refinments and configs
    algoliaHelper.searchOnce(config).then(({ content }) => {
      // make values an object with ids as the keys
      // const derivedValues = deriveValues(content);
      // // dispatch the results here
      // dispatch(setResults(derivedValues));
      // if there is results from algolia, check availability
      if (content.hits.length) {
        // check availability on the parkpnp server
        getAvailability({ content, searchConfig }).then((dataWithAvailabilityCheck) => {
          // update store.
          dispatch(setResults(dataWithAvailabilityCheck));
          if (!Object.keys(dataWithAvailabilityCheck).length) {
            dispatch(setSearchType('ZOOM_OUT_MAP'));
          } // end if 
        }).catch((err) => {
          const derivedValues = deriveValues(content)
          dispatch(setResults(derivedValues));
          if (!Object.keys(derivedValues).length) {
            dispatch(setSearchType('ZOOM_OUT_MAP'));
          } // end if 
        });
      }  else {
        dispatch(setResults({}));
        dispatch(setSearchType('ZOOM_OUT_MAP'));
      }

    }).catch((err) => {
      // dispatch err here
    });
  };
}; // end boundarySearch

export const getSearchResults = () => {
  return (dispatch, getState) => {
    // get current store state
    const storeState = getState();
    // get the searchConfgi state from the store.
    const { searchConfig } = storeState;
    
    const algoliaHelper = api.algolia.algoliaHelper();
    
    // add numeric refinments: facilityIds and propertyTypes.
    addNumericRefinement(algoliaHelper, storeState);

    // get algolia options
    let config = algoliaConfig(searchConfig);
    // search algolia with the above refinments and configs
    algoliaHelper.searchOnce(config).then(({ content }) => {
      // make values an object with ids as the keys
      // const derivedValues = deriveValues(content)
      // if selected parking space is active while getting new results; close it.
      if (storeState.selectedParkingSpace) 
        dispatch(setSelectedParkingSpace(null));

      // if filters are active, close the filters
      if (storeState.uiFlags.activeSearchFilters)
        dispatch(toggleSearchFilters(false))
      
      // dispatch the results here
      // dispatch(setResults(derivedValues));

      // if there is results from algolia, check availability
      if (content.hits.length) {
        // check availability on the parkpnp server
        getAvailability({ content, searchConfig }).then((dataWithAvailabilityCheck) => {
          // update store.
          dispatch(setResults(dataWithAvailabilityCheck));
          if (!Object.keys(dataWithAvailabilityCheck).length) {
            dispatch(setSearchType('ZOOM_OUT_MAP'));
          } // end if 
        }).catch((err) => {
          const derivedValues = deriveValues(content)
          dispatch(setResults(derivedValues));
          if (!Object.keys(derivedValues).length) {
            dispatch(setSearchType('ZOOM_OUT_MAP'));
          } // end if 
        });
      } else {
        dispatch(setResults({}));
        dispatch(setSearchType('ZOOM_OUT_MAP'));
      }
    }).catch((err) => {
      // dispatch err here
    });
  };
};

function algoliaConfig (searchConfig) {
  let config = {}
  if (
    searchConfig.searchBar.location.lat && 
    searchConfig.searchBar.location.lng
  ) {
    config.aroundLatLng = `${searchConfig.searchBar.location.lat}, ${searchConfig.searchBar.location.lng}`;
    config.aroundRadius = searchConfig.map.currentRadius || 1800;
  }
  
  return config;
};

// get the map radius
function mapRadius(lat1, lon1, lat2, lon2) {
  const R = 6371e3; // metres
  const φ1 = toRadians(lat1);
  const φ2 = toRadians(lat2);
  const Δφ = toRadians((lat2-lat1));
  const Δλ = toRadians((lon2-lon1));
  
  const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ/2) * Math.sin(Δλ/2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  
  const d = R * c;

  return Math.ceil(d);

  function toRadians(latlngValue) {
    return latlngValue * Math.PI / 180;
  }
} // end mapRadius

// add filters to algolia
function addNumericRefinement (algoliaHelper, storeState) {
  algoliaHelper.clearRefinements('facility_ids');
  for (let facilityKey in storeState.searchConfig.filters.facilities) {
    if (storeState.searchConfig.filters.facilities[facilityKey].active)
      algoliaHelper.addNumericRefinement('facility_ids', '=', facilityKey);
  } // end for

  algoliaHelper.clearRefinements('instant_booking');
  if (storeState.searchConfig.filters.instantBooking) {
    algoliaHelper.addNumericRefinement('instant_booking', '=', 1);
  } // end if
  
  const propertyType = storeState.searchConfig.filters.propertyTypes.activePropertyType;
  algoliaHelper.clearRefinements('type_of_property_id');
  
  if (Object.keys(propertyType).length && propertyType.id !== -1) {
    algoliaHelper.addNumericRefinement('type_of_property_id', '=', propertyType.id);
  }

  algoliaHelper.clearRefinements('per_month');
  if (storeState.searchConfig.searchBar.period === 'MONTHLY') {
    algoliaHelper.addNumericRefinement('per_month', '>=', 0);
  }
}; // end addNumericRefinement

function deriveValues (content, availabilityArray = null) {
  let availabilityToObj = null;

  // derive data to an easy to reference by id data strucutre.
  if (availabilityArray) {
    availabilityToObj = availabilityArray.reduce((total, val, i) => {
      total[val.id] = val;
      return total;
    }, {});
  } // end if

  return content.hits.reduce((total, val, i) => {
    const checkedInAvailabilityApi = availabilityToObj && availabilityToObj[val.id];
    total[val.id] = val;
    // algolia returns values orderd by distance; keep track of this for sorting on the front-end
    total[val.id]['distanceOrder'] = i;
    
    total[val.id]['displayPrice'] = checkedInAvailabilityApi ? (
      + availabilityToObj[val.id].display_price.replace(/\D/g,'') === 0 ? 'FREE' : availabilityToObj[val.id].display_price
     ) : (
       + val.lowest_cost[0].replace(/\D/g,'') === 0 ? 'FREE' : val.lowest_cost[0]
     );

    if (!availabilityToObj) {
      total[val.id]['available'] = true;
    } else {
      total[val.id]['available'] = checkedInAvailabilityApi ? availabilityToObj[val.id].available : false;
    }
    
    total[val.id]['lowestPriceInCents'] = total[val.id]['available'] ? ( 
      total[val.id]['displayPrice'] === 'FREE' ? 0 : +total[val.id]['displayPrice'].replace(/\D/g,'')
    ) : (
      total[val.id]['displayPrice'] === 'FREE' ? 0 : 10e10
    );
    
    return total;
  }, {});
};


function getAvailability({ content, searchConfig}) {
  const MAX_SPACES_PER_REQ = 99;
  const { startTime, endTime }  = getTimes();
  const period = searchConfig.searchBar.period.toLowerCase();

  // break up requests; lambda can only handle 99 spaces at a time
  const reqs = (new Array(Math.ceil(content.hits.length / MAX_SPACES_PER_REQ)))
    .fill(undefined)
    .map((v, i) => {
      const offset = i * MAX_SPACES_PER_REQ;
      const limit = (i + 1) * MAX_SPACES_PER_REQ;
      const parkingSpaceIds = content.hits.slice(offset, limit).map(p => p.id);
      
      return api.checkAvalability({ parkingSpaceIds, startTime, endTime, period });
    });
  
  // fire all requests from above
  return axios.all(reqs).then((res) => {
    // put values back together
    const dataWithAvailabilityCheck = res.reduce((acc, val) => ([...acc, ...val.data.data]), []);
    // derive data into format required.
    const derivedValues =  deriveValues(content, dataWithAvailabilityCheck);

    recordImpressions(derivedValues, searchConfig.searchBar.location);

    return derivedValues;
  });

  function getTimes() {
    let startTime;
    let endTime;

    if (searchConfig.searchBar.period === 'HOURLY') {
      startTime = `${searchConfig.searchBar.startDate.format('DD/MM/YYYY')} ${searchConfig.searchBar.startTime.slice(0, 2)}:${searchConfig.searchBar.startTime.slice(2)}:00`;
      endTime = `${searchConfig.searchBar.endDate.format('DD/MM/YYYY')} ${searchConfig.searchBar.endTime.slice(0, 2)}:${searchConfig.searchBar.endTime.slice(2)}:00`;
    } else {
      startTime = `${searchConfig.searchBar.startDate.format('DD/MM/YYYY')} 00:00:00`;
      endTime = `${searchConfig.searchBar.startDate.clone().add(31, 'days').format('DD/MM/YYYY')} 00:00:00`;
    }


    return {
      startTime,
      endTime,
    };
  } // end getTimes
} // end getAvailability

function recordImpressions(parkingSpaces, location) {
  if (Object.keys(parkingSpaces).length) {
    let impressions = [];
    for (let key in parkingSpaces) {
      const p = parkingSpaces[key];
      impressions.push({
        id: p.id,
        position: p.distanceOrder + 1,
        list: 'Map List'
      });
    } // end for 

    window.dataLayer.push({
      'ecommerce': {
        'currencyCode': 'EUR',
        impressions,
      }
    });
  } // end if
}