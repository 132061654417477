import { SET_SELECTED_PARKING_SPACE } from '../../actionTypes';

const initialState = null; // id of parking space

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PARKING_SPACE:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;