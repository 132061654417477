import React from 'react';

import ParkingSearchBarContainer from '../../../../components/ParkingSearchBarContainer/ParkingSearchBarContainer'
import { Wrapper, ParkingSearchBarWrapper, Header } from './ParkingMobileSearchBar.style.js'

const ParkingMobileSearchBar = (props) => {
  return (
    <Wrapper column wrap="nowrap" justifyContent="flex-start" alignItems="stretch">
      <Header>
        <h5 className="heading-font text-uppercase">{props.translations.update_search} <i onClick={props.onClose} className="m-l-2 fa fa-times"></i></h5>
      </Header>
      <ParkingSearchBarWrapper>
        <ParkingSearchBarContainer 
          isMobile
          provideDates
          period={props.period}
          location={props.location}
          dates={props.dates}
          onChange={(searchBarState) => props.onChange(searchBarState)} 
          onSubmit={(searchBarState) => props.onSubmit(searchBarState)}
          translations={props.translations}
        />
      </ParkingSearchBarWrapper>
    </Wrapper>
  );
};

export default ParkingMobileSearchBar;