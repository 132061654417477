import React from 'react';
import { FiltersWrapper, FilterButton } from './ParkingDiscoveryFilters.style';
import FavouriteLocationButton from './FavouriteLocationButton';
import { Select } from '../../../../styledComponents/form';

const ParkingDiscoveryFilters = (props) => { 
  const parkingLocation = () => {
    if (!props.address) return null;
    
    const splitAdd = props.address.split(',');
    return splitAdd.length > 1 ? splitAdd[1].trim() + ` ${props.translations.parking}` : splitAdd[0].trim() + ` ${props.translations.parking}`;
  } // end parkingLocation

  return (
    <FiltersWrapper>
      <div className="left">
        <span className="heading-font text-uppercase">{parkingLocation()}</span>
        <Select value={props.sortValue} onChange={props.onSortChange} className="text-capitalize">
          <option value="distance">{props.translations.distance}</option>
          <option value="price">{props.translations.price}</option>
        </Select>
        
      </div>
      <div className="right">
        <span>{props.searchResultsCount} {props.translations.spots_available}</span>
        <FilterButton
          active={props.anyFilterSet}
          className="btn btn-link text-capitalize"
          onClick={() => props.toggleSearchFilters(!props.activeSearchFilters)}
        >
          {props.translations.filter_spots}
        </FilterButton>
      </div>
      <FavouriteLocationButton 
        address={props.address} 
        lat={props.lat} 
        lng={props.lng}
        translations={props.translations}
        authentication_token={props.authentication_token}
      />
      
    </FiltersWrapper>
  )
};

export default ParkingDiscoveryFilters;