import React, { Component } from 'react';
import { Transition } from 'react-spring/renderprops';
import { connect } from 'react-redux';

import { setSelectedParkingSpace } from '../../../redux/parkingDiscoveryStore/selectedParkingSpace/actions';
import ParkingDetailPopup from './ParkingDetailPopup/ParkingDetailPopup';

class ParkingDetailPopupContainer extends Component {
  render() {
    return (
      <Transition
        items={!!this.props.selectedParkingSpace}
        from={{ position: 'absolute', opacity: 0, left: '-100%' }}
        enter={{ opacity: 1, left: this.props.isMobile ? '0' : '100%' }}
        leave={{ opacity: 0, left: '-100%' }}
      >
        {
          toggle => toggle && ((style) => (
            <ParkingDetailPopup 
              style={style}
              activePopup={this.props.selectedParkingSpace}
              handlePopupClose={() => this.props.setSelectedParkingSpace(null)}
              facilities={this.props.facilities}
              translations={this.props.translations}
              searchOptions={this.props.searchBar}
            />
          ))
        }
      </Transition>
    );
  } // end render
} // end ParkingDetailPopupContainer

const mapStateToProps = ({ 
  selectedParkingSpace, 
  searchConfig: { filters: { facilities }, searchBar}, 
  uiFlags: { isMobile },
  translations,
  
}) => ({
  selectedParkingSpace,
  facilities,
  isMobile,
  translations,
  searchBar,
});

export default connect(mapStateToProps, { 
  setSelectedParkingSpace,
})(ParkingDetailPopupContainer);