import React from 'react';
import { FiltersWrapper, FilterCheckboxes, FilterItem, SubmitButtonWrapper, Scroll } from './ParkingDiscoveryFilterPopup.style';

const ParkingDiscoveryFilterPopup = (props) => {
  if (!props.activeSearchFilters) return null;

  const renderFacilitiesFilters = () => {
    const facilities = props.filters.facilities;
    return Object.keys(facilities).map((filterKey) => {
      return (
        <FilterItem key={filterKey}>
          <label 
            className="check-box-1"
            style={{display: 'flex'}}
          >
            <input 
              type="checkbox" 
              onChange={() => {
                props.setSearchType('FILTER_CHANGE');
                props.setFilter({ filterType: 'facilities', filterKey: filterKey, value: !facilities[filterKey].active });
              }}
              checked={facilities[filterKey].active}
            />
            <div className="check-1">
              <i className="fas fa-check"></i>
            </div>
            <span className="pl-3">
              {facilities[filterKey].name}
            </span>
          </label>
        </FilterItem>
      );
    });
  };

  const renderInstantBooking = () => {
    const { instantBooking } = props.filters;
    return (
      <FilterItem className="instant-book">
        <label>
          <span className="text-capitalize"><i className="fa fa-bolt bolt mr-3"></i>{props.translations.instant_book}</span>
          <div className="switch">
            <input 
              onChange={() => {
                props.setSearchType('FILTER_CHANGE');
                props.setInstantBookingFilter(!instantBooking);
              }}
              checked={instantBooking}
              type="checkbox"  
            />
            <div className="slider round" ></div>
          </div>
        </label>
      </FilterItem>
    );
  };

  const renderPropertyTypes = () => {
    const propertyTypes = props.filters.propertyTypes.data;
    const activePropertyType = props.filters.propertyTypes.activePropertyType;

    let orderedProperyTypes = [];

    Object.keys(propertyTypes).forEach((propertyKey) => {
      // // value={facilities[filterKey].active} 
      const item = (
        <FilterItem  key={propertyKey}>
          <label 
            style={{display: 'flex'}}
            className="check-box-1"
          >
            <input 
              type="radio" 
              onChange={() => {
                props.setSearchType('FILTER_CHANGE');
                props.setPropertyType(propertyTypes[propertyKey]);
              }}
              checked={
                activePropertyType.id === propertyTypes[propertyKey].id
              }
            />
            <div className="check-1">
              <i className="fas fa-check"></i>
            </div>
            <span className="pl-3">
              {propertyTypes[propertyKey].name}
            </span>
          </label>
        </FilterItem>
      );
      
      if (propertyKey == -1) {
        orderedProperyTypes.unshift(item);
      } else {
        orderedProperyTypes.push(item);
      }
    });

    return orderedProperyTypes;
  }

  return (
    <FiltersWrapper column wrap="nowrap" justifyContent="flex-start" align-items="stretch">
      <Scroll>
        <div>
          <h5 className="text-center heading-font text-uppercase">{props.translations.instant_book}</h5>
          <FilterCheckboxes wrap="wrap" justifyContent="flex-start" alignItems="flex-start">
            {renderInstantBooking()}
          </FilterCheckboxes>
        </div>

        <hr />

        <div>
          <h5 className="text-center heading-font text-uppercase">{props.translations.facilities}</h5>
          <FilterCheckboxes wrap="wrap" justifyContent="flex-start" alignItems="flex-start">
            {renderFacilitiesFilters()}
          </FilterCheckboxes>
        </div>

        <hr />

        <div>
          <h5 className="text-center heading-font text-uppercase">{props.translations.type_of_property}</h5>
          <FilterCheckboxes wrap="wrap" justifyContent="flex-start" alignItems="flex-start">
            {renderPropertyTypes()}
          </FilterCheckboxes>
        </div>
      </Scroll>

      

      <SubmitButtonWrapper>
        <hr />
        <button 
          className="btn btn-primary btn-block text-capitalize"
          onClick={() => {
            props.closeSearchFilters();
            props.boundarySearch();
          }}
        >
        {props.translations.apply}
        </button>
      </SubmitButtonWrapper>
    </FiltersWrapper>
  );
};

export default ParkingDiscoveryFilterPopup;
