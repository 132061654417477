import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

const configureStore = (initialState) => {
  // create our store
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunk),
  );

  return store;
};

export default configureStore;