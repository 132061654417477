import React, { Component } from 'react';
import { connect } from 'react-redux';

import { sortBy } from '../../../redux/parkingDiscoveryStore/sort/selectors';

import ParkingList from '../../../components/ParkingList/ParkingList';
import { setSelectedParkingSpace } from '../../../redux/parkingDiscoveryStore/selectedParkingSpace/actions';
class ParkingListContainer extends Component {
  render() {
    return (
      <ParkingList 
        data={this.props.searchResults}
        authentication_token={this.props.authentication_token}
        setSelectedParkingSpace={this.props.setSelectedParkingSpace}
        selectedParkingSpace={this.props.selectedParkingSpace}
        translations={this.props.translations}
      ></ParkingList> 

    );
  } // end render
} // end ParkingListContainer

const mapStateToProps = (state) => {
  const { selectedParkingSpace, translations } = state;
  const sortedSearchResults = sortBy(state);
  
  return {
    searchResults: sortedSearchResults,
    selectedParkingSpace,
    translations,
  };
};

export default connect(mapStateToProps, { setSelectedParkingSpace })(ParkingListContainer);