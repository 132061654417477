import React from 'react';
import styled from 'styled-components';

export const SelectWrapper = styled.label`
  max-width: 100%;
  position: relative;
  margin: 0;
  height: 2.2em;

  select {
    height: 100% !important;
    width: 100%;
    padding: 0;
    padding-left: 0.5em;

    border: 1px solid #C9D4DE;
    box-shadow: none;

    /* This is to remove the arrow of select element in IE */
    &::-ms-expand {	display: none; }

    -webkit-appearance: none;
    appearance: none;
  }



  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1em;

    transform: translateY(-50%) rotate(45deg);
    transform-origin: 100% 100%;

    width: 0.5em;
    height: 0.5em;

    background: transparent;
    border-right: 1px solid #C9D4DE;
    border-bottom: 1px solid #C9D4DE;
  }
`;

export const Select = ({ children, ...props }) => (
  <SelectWrapper>
    <select {...props}>
      {children}
    </select>
  </SelectWrapper>
);
