import React from 'react';
import styled from 'styled-components';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorTemplate />
      )
    }

    return this.props.children; 
  }
}

const ErrorTemplate = () => {
  return (
    <ErrorTemplateWithStyling>
      <div className="sign error-page-wrapper background-color background-image">
        <div className="sign-container">
          <div className="nob"></div>
          <div className="post left"></div>
          <div className="post right"></div>
          <div className="pane">
            <div className="headline sign-text-color">
              500
            </div>
            <div className="context sign-text-color">
              Oops, something went wrong!
              <br />
            </div>
          </div>
        </div>

        <div className="text-container">
          <div className="headline secondary-text-color">
            500
          </div>
          <div className="context primary-text-color">
            <p>
              You may want to head back to the homepage.
              <br />
              If you think something is broken, report a problem.
            </p>
          </div>
          <div className="buttons-container">
            <a className="border-button" href="http://parkpnp.com" target="_blank">Home Page</a>
            <a className="border-button" href="mailto:support@parkpnp.com" target="_blank">Report Problem</a>
          </div>
        </div>
      </div>
    </ErrorTemplateWithStyling>
  );
}

const ErrorTemplateWithStyling = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  padding-top: 60px;
  background-color: #179eca;

  .sign.error-page-wrapper {
    height: 100%;
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
    background-color: #179eca;
    position:relative;
  }

  .sign.error-page-wrapper .sign-container {
    width:450px;
    height:415px;
    margin:0 auto;
    position: relative;    
    text-indent:-20px;
  }


  .sign.error-page-wrapper .sign-container .nob {
    height:44px;
    width:44px;
    border-radius: 99px;
    border:12px solid #343c3f;
    position: absolute;
    top:0px;
    left:50%;
    margin-left:-22px;
  }

  .sign.error-page-wrapper .sign-container .post {
    transition:background-color .5s linear;
    width: 190px;
    height: 15px;
    top: 71px;
    background-color: #343c3f;
  }

  .sign.error-page-wrapper .sign-container .post.left {
    position: absolute;
    transform:rotate(-30deg);
    left:35px;
  }

  .sign.error-page-wrapper .sign-container .post.right {
    position: absolute;
    transform:rotate(30deg);
    right:35px;
  }

  .sign.error-page-wrapper .sign-container .pane {
    transition:background-color .5s linear, border-color .5s linear;
    box-shadow: 0 5px 0 rgba(0,0,0,.1) inset, 5px 0 0 rgba(0,0,0,.1) inset, 15px 15px 0 rgba(0,0,0,.1);
    background-color: #fff;
    border:20px solid #343c3f;
    height:300px;
    text-align: center;
    position: absolute;
    top: 115px;
    left:0px;
    right:0px;
  }

  .sign.error-page-wrapper .sign-container .pane .headline {
    transition:color .5s linear;
    margin-top:65px;
    margin-bottom: 10px;
    font-size:54px;
    line-height:68px;
    font-weight:600;
    letter-spacing: -2px;
    text-transform: uppercase;
    color:#ffba00;
  }

  .sign.error-page-wrapper .sign-container .pane.just-header .headline {
    margin-top:100px;
  }

  .sign.error-page-wrapper .sign-container .pane .context {
    transition:color .5s linear;
    color:#ffba00;
    font-size:24px;
    line-height: 32px;
  }


  @media screen and (max-width: 500px) {
    .sign.error-page-wrapper {
      padding-top:10%;
    }
    .sign.error-page-wrapper .sign-container {
      width:280px;
      top: 0px !important;
    }
    .sign.error-page-wrapper .sign-container .post {
      width:100px;
      top:50px;
    }
    .sign.error-page-wrapper .sign-container .pane {
      top:70px;
      height:220px;
    }
    .sign.error-page-wrapper .sign-container .pane .headline {
      margin-top:20px;
      font-size:45px;
      margin-bottom: 6px;
    }
    .sign.error-page-wrapper .sign-container .pane.just-header .headline {
      margin-top: 39px;
      line-height: 55px;
    }
    .sign.error-page-wrapper .sign-container .pane .context {
      font-size:20px;
      line-height: 28px;
    }
  }




  .sign.error-page-wrapper .text-container {
    max-width:425px;
    position: absolute;
    bottom:20px;
    left:35px;
  }

  .sign.error-page-wrapper .text-container .headline {
    transition:color .5s linear;
    font-size:40px;
    line-height: 52px;
    letter-spacing: -1px;
    margin-bottom: 5px;
    color:rgba(255,255,255,.3);
  }
  .sign.error-page-wrapper .text-container .context {
    transition:color .5s linear;
    font-size:18px;
    line-height:27px;
    color:#fff;
  }
  .sign.error-page-wrapper .text-container .context p {
    margin:0;
  }
  .sign.error-page-wrapper .text-container .context p + p {
    margin-top:10px;
  }
  .sign.error-page-wrapper .buttons-container {
    margin-top: 20px;
  }

  .sign.error-page-wrapper .buttons-container a {
    transition: text-indent .2s linear, color .5s linear, border-color .5s linear;
    font-size:16px;
    text-transform: uppercase;
    text-decoration: none;
    color:#fff;
    border:2px solid white;
    border-radius: 99px;
    padding:9px 0 10px;
    width:195px;
    overflow: hidden;
    text-align:center;
    display:inline-block;
    position: relative;
  }

  .sign.error-page-wrapper .buttons-container a:hover {
    background-color:rgba(255,255,255,.1);
    text-indent: 17px;
  }

  .sign.error-page-wrapper .buttons-container a:first-child {
    margin-right:25px;
  }

  .sign.error-page-wrapper .buttons-container .fa {
    transition:left .2s ease-out;
    position: absolute;
    left:-50px;
  }

  .sign.error-page-wrapper .buttons-container .fa-warning {
    font-size:16px;
    top:14px;
  }

  .sign.error-page-wrapper .buttons-container a:hover .fa-warning {
    left:0px;
  }

  .sign.error-page-wrapper .buttons-container .fa-power-off {
    font-size:16px;
    top:14px;
  }

  .sign.error-page-wrapper .buttons-container a:hover .fa-power-off {
    left:0px;
  }

  .sign.error-page-wrapper .buttons-container .fa-home {
    font-size:18px;
    top:12px;
  }

  .sign.error-page-wrapper .buttons-container a:hover .fa-home {
    left:25px;
  }

  @media screen and (max-width: 500px) {
  .sign.error-page-wrapper .text-container {
      bottom:20px;
      left:20px;
      right:20px;
    }
  .sign.error-page-wrapper .text-container .header {
      font-size:32px;
      line-height:40px;
    }
  .sign.error-page-wrapper .text-container .context {
      font-size:15px;
      line-height: 22px;
    }
  .sign.error-page-wrapper .buttons-container {
      overflow: hidden;
    }
  .sign.error-page-wrapper .buttons-container a {
      font-size:14px;
      padding:8px 0 9px;
      width:45%;
      float:left;
      margin:0;
    }
  .sign.error-page-wrapper .buttons-container a + a {
      float:right;
    }
  .sign.error-page-wrapper .buttons-container a:hover {
      text-indent: 0px;
    }
  .sign.error-page-wrapper .buttons-container .fa {
      display:none;
    }
  }
`