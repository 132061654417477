import { UPDATE_PARKING_LOADER } from '../../actionTypes';

const initialState = {
  searching: false, // true when retrieving algolia results
  avalabilityChecking: false, // true when checking avalability against server
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PARKING_LOADER:
      return { ...state, [action.key]: action.value }
    default:
      return state;
  }
};

export default reducer;