import React from 'react';
import { Wrapper } from './ParkingMobileSearchButton.style.js';
import { formatDateTime } from '../../../../utilities/helpers';

const ParkingMobileSearchButton = ({ onClick, searchConfig, translations }) => {
  return (
    <Wrapper 
      onClick={onClick}
      column 
      wrap="nowrap" 
      justifyContent="flex-start" 
      alignItems="stretch"
    >
      <div>
        <i className="fas fa-search" />
        <span className="text-capitalize">{searchConfig.location.address || translations.select_location}</span>
      </div>
      <div>
        <span>
          <span className="text-capitalize">{translations.start_time}</span>
          {
            searchConfig.period === 'HOURLY' &&
            <span className="text-capitalize">{searchConfig.startDate ? formatDateTime(searchConfig.startDate, searchConfig.startTime, translations) : translations.select_time}</span>
          }

          {
            searchConfig.period === 'MONTHLY' &&
            <span className="text-capitalize">{searchConfig.startDate ? searchConfig.startDate.format('Do MMM') : translations.select_time}</span>
          }


        </span>
        <span>
          <span className="text-capitalize">{translations.end_time}</span>
          {
            searchConfig.period === 'HOURLY' &&
            <span className="text-capitalize">{searchConfig.startDate ? formatDateTime(searchConfig.endDate, searchConfig.endTime, translations) : translations.select_time}</span>
          }

          {
            searchConfig.period === 'MONTHLY' &&
            <span className="text-capitalize">{searchConfig.startDate ? searchConfig.startDate.clone().add(31, 'days').format('Do MMM') : translations.select_time}</span>
          }
        </span>
      </div>
    </Wrapper>
  );
}; // end ParkingMobileSearchButton

export default ParkingMobileSearchButton;