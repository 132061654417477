import React from 'react';
import { Select } from '../../styledComponents/form';
import PropTypes from 'prop-types';
import * as moment from 'moment';

class TimeSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      options: [],
    };
  }

  componentDidMount = () => {
    this.updateOptions();
  } // end componentDidMount

  componentDidUpdate = (prevProps) => {
    if (this.props.startTime !== prevProps.startTime) {
      this.updateOptions();
    }

    // check if start time if after end time
    if (
      this.props.startTime && this.state.value && 
      (parseInt(this.props.startTime) >= parseInt(this.state.value.value))
      ) {
      this.setState({ value: null });
    } // end if
  } // end componentDidUpdate

  updateOptions = () => {
    const options = this.createTimeOptions();
    this.setState({ options, });
  }

  createTimeOptions = () => {
    let startTime = {
      hour: this.props.startTime ? parseInt(this.props.startTime.substr(0, 2)) : 0,
      minute: this.props.startTime ? parseInt(this.props.startTime.substr(2)) : -15,
    };

    let options = [];
    const time = moment.utc('2016-01-01T00:00:00');

    // set the time to the startTime.
    time.add(startTime.hour, 'h');
    time.add(startTime.minute, 'm');

    // fill in options.
    let optionsFilled = false;
    while (!optionsFilled) {
      time.add(15, 'm');
      options.push({
        value: time.format('HHmm'),
        label: time.format('HH:mm'),
      });

      if (time.hour() === 23 && time.minute() === 45) 
        optionsFilled = true;
    } // end while
  
    return options;
  } // end createTimeOptions

  handleChange = (e) => {
    this.props.onChange(e.target.value);
  } // end handleChange

  render() {

    return (
      <Select
        value={this.props.value}
        onChange={this.handleChange}
        placeholder="Select a time"
      >
        {
          this.state.options.map(({ value, label }) => (
            <option key={value} value={value}>{label}</option>
          ))
        }
      </Select>
    )
  }
} // end TimeSelect

TimeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  startTime: PropTypes.string,
};

TimeSelect.defaultProps = {
  startTime: '',
  value: '',
}

export default TimeSelect;