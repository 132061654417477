import { NEW_PARKING_SEARCH_RESULTS } from '../../actionTypes';

const initialState = {
  // [id]: ...,
  // [id]: ...,
  // ...
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NEW_PARKING_SEARCH_RESULTS:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;