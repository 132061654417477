import { SET_FAVORITE_MODAL } from '../../actionTypes';

const initialState = false; // id of parking space

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FAVORITE_MODAL:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;