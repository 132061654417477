import React, { Component } from 'react';
import createHTMLMapMarker from '../../../utilities/html-map-marker';
import { MapWrapper } from './ParkingDiscoveryMap.style';
import { debounce } from '../../../utilities/debounce';

class ParkingDiscoveryMap extends Component {
  static DEFAULT_ZOOM = 15
  static MAX_ZOOM = 20
  static MIN_ZOOM = 13
  static DEBOUNCE_BOUNDRY_SEARCH = 500; // ms

  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
    this.map = null;
    this.markers = {};
    this.activeMarker = null;

    this.zoomOutAndSearch = false;

  }

  componentDidMount() {
    if (Object.keys(this.props.parkingSpaces).length) {
      this.createMarkers();
    }

    if (this.props.selectedParkingSpace) {
      this.centerMapOnSelectedParkingSpace(this.props.selectedParkingSpace);
    }

    if (
      !this.map &&
      this.props.searchConfig.searchBar.location.lat &&
      this.props.searchConfig.searchBar.location.lat
    ) {
      this.setupGoogleMaps();
    }

    if (this.props.searchConfig.searchType === 'ZOOM_OUT_MAP') {
      this.zoomOut();
    }
  } // end componentDidMount

  componentDidUpdate(prevProps) {
    if (
      !this.map &&
      this.props.searchConfig.searchBar.location.lat &&
      this.props.searchConfig.searchBar.location.lat
    ) {
      this.setupGoogleMaps();
    }


    if (prevProps.parkingSpaces !== this.props.parkingSpaces)
      this.createMarkers();

    if (
      this.props.selectedParkingSpace &&
      this.props.selectedParkingSpace !== prevProps.selectedParkingSpace
    ) {
      this.centerMapOnSelectedParkingSpace(this.props.selectedParkingSpace);
    }

    if (
      this.activeMarker && !this.props.selectedParkingSpace
    ) {
      this.activeMarker.removeActiveClass();
      this.activeMarker = null;
    }

    if (
      prevProps.searchConfig.searchType !== this.props.searchConfig.searchType &&
      this.props.searchConfig.searchType === 'ZOOM_OUT_MAP'
    ) {
      this.zoomOut();
    }
  } // end componentDidUpdate

  componentWillUnmount() {
    // remove event listener from map
    google.maps.event.clearListeners(this.map, 'bounds_changed');
  } // end componentWillUnmount

  zoomOut = debounce(() => {
    const currentZoom = this.map.getZoom();
    if (currentZoom > ParkingDiscoveryMap.MIN_ZOOM) {
      this.map.setZoom(currentZoom - 1);
    } // end if
  }, 1000) // end zoomOut

  centerMapOnSelectedParkingSpace = debounce((parkingSpace) => {
    // if it's not mobile and not list view then center map.
    if (!this.props.mobileListViewActive) {
      // pan to center of new selected marker
      const [ lat, lng ] = parkingSpace.location;
      this.map.panTo(new google.maps.LatLng(lat, lng));
    }


    // remove any previous marker class.
    if (this.activeMarker) {
      this.activeMarker.removeActiveClass('map-marker-active');
    }

    // set new marker active
    this.activeMarker = this.markers[parkingSpace.id];
    this.activeMarker.setActiveClass('map-marker-active');
  }, 500) // end centerMapOnSelectedParkingSpace

  createMarkers() {
    // set map center
    const { lat, lng } = this.props.searchConfig.searchBar.location;
    const searchType = this.props.searchConfig.searchType;

    if (searchType === 'LOCATION_CHANGE' && lat && lng) {
      this.map.setZoom(ParkingDiscoveryMap.DEFAULT_ZOOM);
      this.map.panTo(new google.maps.LatLng(lat, lng));
    } // end if


    // if there's already markers references saved, remove any that aren't part of the result
    if (Object.keys(this.markers).length) {
      for (let markersKey in this.markers) {
        // update label if new results contain the saved marker
        if (this.props.parkingSpaces[markersKey]) {
          this.updateMarkerLabel(markersKey)
        } else {
          // remove the marker if not present in new results
          this.removeMarker(markersKey);
        }
      }
    } // end if


    for (let parkingSpaceId in this.props.parkingSpaces) {
      // skip markers we already updated in above logic.
      if (this.markers[parkingSpaceId]) {
        continue;
      }

      const p = this.props.parkingSpaces[parkingSpaceId];
      this.markers[p.id] = this.createMarker(p);
    } // end for loop

    if (this.activeMarker && !this.props.parkingSpaces[this.props.selectedParkingSpace.id]) {
      this.activeMarker.removeActiveClass();
      this.activeMarker = null;
      this.props.setSelectedParkingSpace(null);
    }
  } // end createMarker

  createMarker = (parkingSpace) => {
    const markerHtml = this.createMarkerLabel(parkingSpace);

    const latLng = new google.maps.LatLng(
      parkingSpace.location[0],
      parkingSpace.location[1],
    );

    const marker = createHTMLMapMarker({
      latlng: latLng,
      map: this.map,
      html: markerHtml,
    });

    // remove listener
    marker.addListener('click', () => {
      this.props.setSelectedParkingSpace(this.props.parkingSpaces[parkingSpace.id]);
    });

    return marker;
  } // end createMarker

  updateMarkerLabel = (parkingSpaceID) => {
    const marker = this.markers[parkingSpaceID];
    const newLabel = this.createMarkerLabel(this.props.parkingSpaces[parkingSpaceID]);

    marker.updateMarkerLabel(newLabel)

    return marker;
  } // end updateMarkerLabel

  removeMarker(parkingSpaceID) {
    const marker = this.markers[parkingSpaceID];
    // remove event listener
    google.maps.event.clearListeners(marker, 'click');
    // remove marker from map
    marker.remove();
    // delete ref from markers object
    delete this.markers[parkingSpaceID];
  } // end removeMarker

  createMarkerLabel(parkingSpace) {
    const price = parkingSpace.displayPrice;
    const parkingSpaceAvailable = parkingSpace.available;

    return `
      <div class="availability-${!!parkingSpaceAvailable}">
        <div class="parking_space_tooltip">
          <div class="inner">
            ${parkingSpaceAvailable && parkingSpace.marker_style == 'commercial' ? '<span class="parking_icon">P</span>' : ''}
            ${parkingSpaceAvailable ? price : this.props.translations.sold}
            ${parkingSpaceAvailable && parkingSpace.instant_booking ? '<i class="fa fa-bolt bolt"></i>' : ''}
          </div>
        </div>
        <div class="arrow"></div>
      </div>
    `
  } // end createMarkerLabel

  setupGoogleMaps = () => {
    this.map = new google.maps.Map(this.mapRef.current, {
      center: {
        lat: this.props.searchConfig.searchBar.location.lat,
        lng: this.props.searchConfig.searchBar.location.lng,
      },
      streetViewControl: true,
      clickableIcons: false,
      mapTypeControl: true,
      gestureHandling: "greedy",
      zoom: ParkingDiscoveryMap.DEFAULT_ZOOM,
      minZoom: ParkingDiscoveryMap.MIN_ZOOM,
      maxZoom: ParkingDiscoveryMap.MAX_ZOOM,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      mapTypeControl: false,
      fullscreenControl: false,
      zoomControl: true,
      zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
      },
      scaleControl: true,
      streetViewControl: false,
    });

    // initilize the map radius
    this.setMapRadius();

    const handleBoundsChange = debounce(() => {
      // set map location data.
      this.setMapRadius();

      if (this.props.searchConfig.searchType === 'LOCATION_CHANGE') {
        this.props.setSearchType(null);
        return;
      } // end if

      // do a bounds search
      this.props.boundarySearch();
    }, ParkingDiscoveryMap.DEBOUNCE_BOUNDRY_SEARCH);

    this.map.addListener('bounds_changed', handleBoundsChange);
  } // end setupGoogle Maps

  // store the maps radius to the store.
  setMapRadius = () => {
    const c = this.map.getCenter();

    // set map center
    this.props.setSearchMapCenter({
      lat: c.lat(),
      lng: c.lng(),
    });

    const bounds = this.map.getBounds();
    if (bounds) {
      const latlng = bounds.getNorthEast();
      this.props.setMapFurthestPoint({ lat: latlng.lat(), lng: latlng.lng() });
    } // end if
  } /// end setMapRadius

  // renderMapAndListView
  render() {
    return (
      <MapWrapper id="search_results_map" ref={this.mapRef} />
    )
  }
};

export default ParkingDiscoveryMap;
