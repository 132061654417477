import styled from 'styled-components';
import { FlexContainer, FlexItem } from '../../../../styledComponents/flex';

const popupWidth = 350;
export const PopupWrapper = styled(FlexContainer)`
  position: absolute;
  top: 0;
  left: 100%;
  bottom: 0;
  z-index: 0;

  margin: 10px 0 20px 20px;
  width: ${popupWidth}px;
  background-color: white;
  box-shadow: 1px 2px 3px 0 rgba(33,33,33,.078);
  border: 1px solid #ccc;
  border-radius: 5px;

  & > .popup-arrow {
    position: absolute;
    left: -7px;
    top: 125px;
    transform: rotate(45deg);

    width: 50px;
    height: 50px;

    background-color: white;
    box-shadow: 1px 2px 3px 0 rgba(33,33,33,.078);
    border: 1px solid #ccc;
    border-radius: 2px;
  }

  & > section.wrapper-container {
    position: absolute;
    top: 0;
    left: 0;
    
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;

    background-color: white;
    z-index: 1;
    border-radius: 5px; 
  }

  @media (max-width: 992px) {
    z-index: 4;
    left: 0;
    margin: 0;
    width: 100%;
    border: 0;
    border-radius: 0;
  }
`;


export const ScrollY = styled(FlexItem)`
  overflow-y: scroll;

  padding: 20px;
`;


export const Header = styled(FlexContainer)`
  padding: 20px;

  border-bottom: 1px solid #EDF0F4;

  & > h5 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  & > button {
    font-weight: bold;
    padding: 0.8em;
  }
`;

export const IconsWrapper = styled(FlexContainer)`
  & > * {
    width: 33%;
    margin-bottom: 1em;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    & > img {
      opacity: 0.4 !important;
    }

    span {
      text-align: center;
      font-weight: bold;
      font-size: 0.8em;
    }
  }
`;

export const StreetViewWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 30px;

  border-radius: 5px;

  cursor: pointer;
  background: #1a1a1a;
  color: white;

  & > span {
    flex: 1;
    text-align: center;
    font-size: 1.2em;
  }
`;

export const ImagePreviews = styled(FlexContainer)`
  & > img {
    width: 48%;
  }
`;