import React, { Component } from 'react';
import { connect } from 'react-redux';
import ParkingDiscoveryFilterPopup from './ParkingDiscoveryFilterPopup/ParkingDiscoveryFilterPopup';
import { toggleSearchFilters } from '../../../redux/parkingDiscoveryStore/uiFlags/actions';
import { setFilter, setInstantBookingFilter, setSearchType, setPropertyType } from '../../../redux/parkingDiscoveryStore/searchConfig/actions';
import { boundarySearch } from '../../../redux/parkingDiscoveryStore/searchResults/operations';

class ParkingDiscoveryFilterPopupContainer extends Component {
  closeSearchFilters = () => {
    this.props.toggleSearchFilters(false);
  } // end closeSearchFilters

  render() {
    return (
      <ParkingDiscoveryFilterPopup 
        activeSearchFilters={this.props.activeSearchFilters}
        closeSearchFilters={this.closeSearchFilters}
        filters={this.props.filters}
        setFilter={this.props.setFilter}
        setSearchType={this.props.setSearchType}
        boundarySearch={this.props.boundarySearch}
        setInstantBookingFilter={this.props.setInstantBookingFilter}
        setPropertyType={this.props.setPropertyType}
        translations={this.props.translations}
      />
    );
  }
}

const mapStateToProps = ({ 
  searchConfig: { filters }, 
  uiFlags: { activeSearchFilters },
  translations,
}) => ({
  activeSearchFilters,
  filters,
  translations,
});

export default connect(mapStateToProps, { 
  toggleSearchFilters, 
  setFilter,
  setSearchType, 
  boundarySearch,
  setInstantBookingFilter,
  setPropertyType,
})(ParkingDiscoveryFilterPopupContainer)