import styled, { css } from 'styled-components';

// LAYOUT
const listItemHeight = 100;
const listItemPadding = 12;
const avatarHeightWidth = listItemHeight - (2 * listItemPadding);

// COLORS
const itemBackgroundColor = '#FAFAFA';
const itemBorderColor = '#D9E1E9';

// the item container
export const ListItem = styled.span`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  font-weight: bold;


  /* height: ${listItemHeight}px; */
  width: 100%;

  padding: ${listItemPadding}px;
  border-left: ${({ activeItem }) => activeItem ? '5px solid #027AFF' : '5px solid transparent'};
  border-bottom: 1px solid ${itemBorderColor};
  background-color: ${({ activeItem }) => activeItem ? '#E4F1FE' : itemBackgroundColor};

  cursor: pointer;
`;

// this holds the image
export const AvatarContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;

  height: ${avatarHeightWidth}px;
  width: ${avatarHeightWidth}px;

  overflow: hidden;
`;

// this holds the info on the right of the avatar
export const InfoContainer = styled.section`
  flex: 1;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: space-between;

  padding-left: ${listItemPadding}px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > *:nth-child(2) {
    img {
      margin-right: 10px;
    }
  }
`;

const costLabelBackgroundColor = `#1F1F1F`;
const costLabelBackgroundColorActive = `#157EFB`;
export const CostLabel = styled.span`
  display: inline-block;

  padding: 0px 7px;
  min-width: 66px;
  font-size: 13px;

  color: white;
  text-align: center;
  font-weight: 100;

  border-radius: 3px;

  ${({ active, available}) => {
    if (!available) {
      return css`
        background-color: white;
        color: red;
      `;
    }

    return css`
      background-color: ${active ? costLabelBackgroundColorActive : costLabelBackgroundColor};
    `;
  }}
`;
