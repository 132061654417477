import React, { Component } from 'react';
import ParkingSearchBarContainer from '../../components/ParkingSearchBarContainer/ParkingSearchBarContainer';
import { Wrapper } from './HomeSearchBar.style';

export default class HomeSearchBar extends Component {
  componentDidMount = () => {
    window.dispatchEvent(new Event('resize'));
  };

  onChange = searchBarState => {};

  onSubmit = searchBarState => {
    const url = window.location.origin + window.location.pathname;
    const slash = url.charAt(url.length - 1) === '/' ? '' : '/';

    if (
      searchBarState.location.lat &&
      searchBarState.location.lng &&
      searchBarState.location.address
    ) {
      var redirect_path = `${url}${slash}parking?lat=${
        searchBarState.location.lat
      }&lng=${searchBarState.location.lng}&address=${
        searchBarState.location.address
      }&period=${searchBarState.period || 'HOURLY'}`;

      Turbolinks.visit(redirect_path);
      return;
    }

    var redirect_path = `${url}${slash}parking${
      searchBarState.period
        ? `?period=${searchBarState.period}`
        : '?period=HOURLY'
    }`;
    Turbolinks.visit(redirect_path);
  };

  render() {
    return (
      <Wrapper>
        <ParkingSearchBarContainer
          translations={this.props.translations}
          period={this.props.period}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
        />
      </Wrapper>
    );
  }
} // end HomeSearchBar
