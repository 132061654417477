import React, { Component } from 'react';
import { connect } from 'react-redux';
import ParkingDiscoveryMap from './ParkingDiscoveryMap';
import { setSelectedParkingSpace } from '../../../redux/parkingDiscoveryStore/selectedParkingSpace/actions';
import { 
    setSearchMapCenter, 
    setSearchType, 
    setMapFurthestPoint,
} from '../../../redux/parkingDiscoveryStore/searchConfig/actions';
import { boundarySearch } from '../../../redux/parkingDiscoveryStore/searchResults/operations';

class ParkingDiscoveryMapContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ParkingDiscoveryMap 
        parkingSpaces={this.props.searchResults}
        setSelectedParkingSpace={this.props.setSelectedParkingSpace}
        searchConfig={this.props.searchConfig}
        selectedParkingSpace={this.props.selectedParkingSpace}
        setSearchMapCenter={this.props.setSearchMapCenter}
        setSearchType={this.props.setSearchType}
        setMapFurthestPoint={this.props.setMapFurthestPoint}
        boundarySearch={this.props.boundarySearch}
        isMobile={this.props.uiFlags.isMobile}
        mobileListViewActive={this.props.uiFlags.mobileListViewActive}
        translations={this.props.translations}
      />
    )
  }
};

const mapStateToProps = ({ searchResults, searchConfig, selectedParkingSpace, uiFlags, translations }) => {
  return { 
    searchResults, 
    searchConfig, 
    selectedParkingSpace,
    translations,
    uiFlags,
  };
};

export default connect(mapStateToProps, {
  setSelectedParkingSpace,
  setSearchMapCenter,
  setSearchType,
  setMapFurthestPoint,
  boundarySearch,
})(ParkingDiscoveryMapContainer);