import axios from 'axios';

export const checkAvalability = ({ 
  parkingSpaceIds, 
  startTime, 
  endTime, 
  period,
}) => {
  return axios.post(process.env.AVAILABILITY_API_ENDPOINT, {
    parking_space_ids: parkingSpaceIds,
    start_time: startTime,
    end_time: endTime,
    period,
  }, { headers: {'Accept': 'application/json'} });
};