import styled from 'styled-components';
import { FlexContainer } from '../../../../styledComponents/flex';

export const Wrapper = styled(FlexContainer)`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    z-index: 5;
    background-color: HSLA(210, 64%, 4%, 0.800)
`;

export const Header = styled.header`
  background: white;
  padding: 1em;

  h5 {
    text-align: center;
    font-size: 1.2em;
    margin: 0;
    font-weight: bold;

    i {
      float: right;
      color: #C0C0C0;
    }
  }
`;

export const ParkingSearchBarWrapper = styled.div`
  width: 100%;

  & > * {
    flex-direction: column;
    justify-content: start;
    align-items: stretch;
    background-color: white;
    padding: 0 1.2em;

    .period-container {
      padding: 1em 0;
      padding-top: 0;
      margin: 0;

      & > label {
        flex: 1;
        padding: 0.5em;
        display: block;
        text-align: center !important;

      }
    }

    .search-container {
      flex: initial;
      margin-bottom: 1em;

      & input {
        padding: 0.8em;
        padding-left: 2em;
      }
    }

    .dates-container {
      margin: 0;
      margin-bottom: 1em;

      & > div {
        flex: 1;
      }

      .SingleDatePickerInput_calendarIcon {
        display: none;
      }

      .DateInput_input {
        padding-left: 7px !important;
      }
      
    }

    .submit-container {
      padding: 0.8em;
      font-weight: 0.8em;
      margin-bottom: 1em;
    }
  }
`;
