import { UPDATE_SORT_FIELD } from '../../actionTypes';

const initialState = {
  parkingSpaces: 'distance', // 'distance' || 'price'
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SORT_FIELD:
      return { ...state, parkingSpaces: action.payload };
    default:
      return state;
  }
};

export default reducer;