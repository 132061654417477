import React from 'react';
import { connect } from 'react-redux';
import FacilityIcon from '../../../components/FacilityIcon/FacilityIcon';

const ParkingIconContainer = ({ icons, id, style }) => {
  const idToIcon = {
    '1': icons['cctv'],
    '2': icons['gates'],
    '3': icons['security'],
    '4': icons['key'],
    '5': icons['shelter'],
    '6': icons['underground'],
    '7': icons['car_wash'],
    '8': icons['charging'],
    '9': icons['lighting'],
    '10': icons['permit_required'],
    '11': icons['fob_required'],
    '12': icons['disabled_parking'],
  };

  return (
    <FacilityIcon src={idToIcon[id]} style={style} />
  );
}

const mapStateToProps = ({ icons }) => ({
  icons,
});

export default connect(mapStateToProps)(ParkingIconContainer);