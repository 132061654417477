import { createSelector } from 'reselect'

const toArray = ({ searchResults }) => (
  Object.keys(searchResults).map(key => searchResults[key])
);

const sortValue = ({ sort: { parkingSpaces }}) => parkingSpaces;

const selectedSpaceID = ({ selectedParkingSpace }) => selectedParkingSpace && selectedParkingSpace.id;

export const sortBy = createSelector(
  toArray,
  sortValue,
  selectedSpaceID,
  (values, sortBy, selectedSpaceID) => {
    const sortedValues = values.sort(compare);

    if (sortBy === 'distance' || !selectedSpaceID) return sortedValues;

    // add selected parking space as first el in array
    const selectedIndex = sortedValues.findIndex(v => v.id === selectedSpaceID);
    
    return [
      sortedValues[selectedIndex], 
      ...sortedValues.slice(0, selectedIndex), 
      ...sortedValues.slice(selectedIndex + 1, sortedValues.length)
    ];

    function compare(a, b) {
      
      if (sortBy === 'price' && !a.available) {
        return 1;
      }

      const valueA = sortBy === 'price' ? parseInt(a.lowestPriceInCents) : a.distanceOrder;
      const valueB = sortBy === 'price' ? parseInt(b.lowestPriceInCents) : b.distanceOrder;
      
      if (valueA > valueB) {
        return 1;
      } else if (valueA < valueB) {
        return -1;
      }

      return 0;
    } // end compare
  } // end func
);