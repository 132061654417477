import { 
  TOGGLE_SEARCH_FILTERS, 
  ON_MOBILE_CHANGE,
  MOBILE_LIST_VIEW_CHANGE,
} from '../../actionTypes';

export const toggleSearchFilters = (payload) => ({
  type: TOGGLE_SEARCH_FILTERS,
  payload,
});

export const onMobileChange = (payload) => ({
  type: ON_MOBILE_CHANGE,
  payload,
});

export const mobileListViewChange = (payload) => ({
  type: MOBILE_LIST_VIEW_CHANGE,
  payload,
});