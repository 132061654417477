import React, { Component } from 'react';
import { Provider } from 'react-redux';
import * as moment from 'moment';

import 'moment/locale/fr';
import 'moment/locale/nl';

import configureStore from '../../redux/parkingDiscoveryStore';
import ParkingDiscoveryContainer from './ParkingDiscoveryContainer';
import {
  initilizeFilters,
  setPropertyType,
  setSearchConfigSearchBar,
  setSearchType,
} from '../../redux/parkingDiscoveryStore/searchConfig/actions';
import { getSearchResults } from '../../redux/parkingDiscoveryStore/searchResults/operations';
import { onMobileChange } from '../../redux/parkingDiscoveryStore/uiFlags/actions';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';

class RootContainer extends Component {
  constructor(props) {
    super(props);

    // set locale
    moment.locale(props.locale || 'en');

    // initilize redux store
    const initialState = {
      icons: props.facility_icons,
      translations: props.translations,
    };

    this.state = {
      searchBarDates: this.getInitialDates(),
    };

    this.store = configureStore(initialState);

    // set defualt location
    this.setDefaultLocation({
      dispatch: this.store.dispatch,
      currentRegion: props.all_regions.find(
        r => r.slug === props.current_region
      ),
    });

    const allPropertyType = { id: -1, name: 'All' };
    const allProperties = [allPropertyType, ...props.propertyTypes];
    // initilize the filters
    this.store.dispatch(
      initilizeFilters({
        facilities: props.facilities,
        propertyTypes: allProperties,
      })
    );

    // init the property types to all.
    this.store.dispatch(setPropertyType(allPropertyType));

    // setup initial app load width;
    const isMobile = window.innerWidth < 992;
    this.store.dispatch(onMobileChange(isMobile));
  } // end constructor

  getInitialDates = () => {
    let startDate = moment(new Date());
    let endDate = moment(new Date());

    const url = new URL(window.location.href);
    const periodParam = url.searchParams.get('period');

    if (periodParam === 'MONTHLY') {
      startDate.add(1, 'days');
      endDate.add(2, 'days');
    } // end if

    return {
      startDate: startDate,
      endDate: endDate,
      startTime:
        startDate.minute() >= 30
          ? startDate.add(1, 'hour').format('HH00')
          : startDate.format('HH30'),
      endTime:
        endDate.minute() >= 30
          ? endDate.add(4, 'hour').format('HH00')
          : endDate.add(3, 'hour').format('HH30'),
    };
  }; // end getInitialDates

  setDefaultLocation = ({ dispatch, currentRegion }) => {
    // set the default location with the url params if there are any.
    const params = this.getSearchParams();
    if (params.lat && params.lng && params.address) {
      return this.dispatchDefaultLocation({
        dispatch,
        ...params,
        searchBarDates: this.state.searchBarDates,
      });
    } // end if

    // set default location based on the current region
    const geocoder = new google.maps.Geocoder();
    const latLng = {
      lat: parseFloat(currentRegion.default_map_focus[0]),
      lng: parseFloat(currentRegion.default_map_focus[1]),
    };

    // get the address based on lat, lng.
    geocoder.geocode({ location: latLng }, (res, status) => {
      if (status === 'OK') {
        let city = res.find(l =>
          l.types.includes('administrative_area_level_1')
        );
        const address = city
          ? city.formatted_address
          : res[0].formatted_address;

        this.dispatchDefaultLocation({
          dispatch,
          address,
          lat: latLng.lat,
          lng: latLng.lng,
          period:
            (params &&
              (params.period === 'HOURLY' || params.period === 'MONTHLY') &&
              params.period) ||
            'HOURLY',
          searchBarDates: this.state.searchBarDates,
        });
      } // end if
    }); // end geocoder
  }; // end setDefaultLocation

  dispatchDefaultLocation = ({
    dispatch,
    lat,
    lng,
    address,
    period,
    searchBarDates,
  }) => {
    const newSearchBarConfig = {
      location: { address, lat, lng },
      period: period,
      ...searchBarDates,
    };

    // dispatch initlize location
    dispatch(setSearchConfigSearchBar(newSearchBarConfig));
    dispatch(setSearchType('LOCATION_CHANGE'));
    dispatch(getSearchResults());
  };

  getSearchParams = () => {
    // get the url
    const url = new URL(window.location.href);

    // define params needed.
    // work with old search bar also, that has different params
    const paramsNeeded = [
      ['lat', 'search[lat]'],
      ['lng', 'search[lng]'],
      ['period'],
      ['address', 'search[text]'],
    ];

    // get the params needed
    const params = paramsNeeded.reduce((acc, val) => {
      const param = getParam(val);
      acc[param.key] = +param.value || param.value;
      return acc;

      function getParam(vals) {
        for (let i = 0; i < vals.length; i++) {
          const p = url.searchParams.get(vals[i]);
          if (p) return { key: vals[0], value: p };
        }

        return { key: vals[0], value: null };
      } // end getParam
    }, {});

    // set default period if not already set
    params['period'] =
      ((params['period'] === 'HOURLY' || params['period'] === 'MONTHLY') &&
        params['period']) ||
      'HOURLY';

    return params;
  }; // end getSearchParams

  render() {
    return (
      <ErrorBoundary>
        <Provider store={this.store}>
          <ParkingDiscoveryContainer
            searchBarDates={this.state.searchBarDates}
            authentication_token={this.props.authentication_token}
          />
        </Provider>
      </ErrorBoundary>
    );
  }
} // end

export default RootContainer;
