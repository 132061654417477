import { combineReducers } from 'redux';

import availableParkingSpaces from './availableParkingSpaces/reducer';
import loaders from './loaders/reducer'
import searchConfig from './searchConfig/reducer';
import searchResults from './searchResults/reducer';
import selectedParkingSpace from './selectedParkingSpace/reducer';
import openModal from './favoriteRegion/reducer';
import uiFlags from './uiFlags/reducers';
import sort from './sort/reducer';
import icons from './icons/reducer';
import translations from './translations/reducer';



const rootReducer = combineReducers({
  availableParkingSpaces,
  loaders,
  searchConfig,
  searchResults,
  openModal,
  selectedParkingSpace,
  uiFlags,
  sort,
  icons,
  translations,
});

export default rootReducer;
