import { 
  SET_SEARCH_CONFIG_SEARCH_BAR,
  INITILIZE_FILTERS,
  SET_PARKING_FILTER_FACILITIES,
  SET_PARKING_FILTER_INSTANT_BOOKING,
  SET_PARKING_FILTER_PROPERTY_TYPES,
  SET_SEARCH_MAP_CENTER,
  SET_SEARCH_TYPE,
  SET_SEARCH_MAP_FURTHEST_POINT,
  SET_SEARCH_CURRENT_RADIUS,
  SET_AVAILABILITY_CANCEL_REF,
  SET_SEARCH_LOCATION,
} from '../../actionTypes';

const initialState = {
  searchBar: {
    location: {},
  },

  filters: {
    facilities: {},
    propertyTypes: {
      data: {},
      activePropertyType: {},
    },
    instantBooking: false,
  },

  map: {
    center: null,
    furthestPoint: null, // corner of the viewable map {lat: , lng }.
    currentRadius: null,
  },

  searchType: null, // the type of search, FILTER_CHANGE, BOUNDS_CHANGE, LOCATION_CHANGE, ZOOM_OUT_MAP

  availabilityCheckCancelRef: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_CONFIG_SEARCH_BAR:
      return { ...state, searchBar: action.payload};

    case INITILIZE_FILTERS: 
      return { 
        ...state, 
        filters: {
          ...state.filters,
          facilities: action.payload.facilities,
          propertyTypes: action.payload.propertyTypes,
        }
      };
    
    case SET_SEARCH_LOCATION:
      return { ...state, searchBar: { ...state.searchBar, location: action.payload }};
    case SET_PARKING_FILTER_INSTANT_BOOKING:
      return {
        ...state,
        filters: {
          ...state.filters,
          instantBooking: action.payload,
        },
      };

    case SET_PARKING_FILTER_FACILITIES:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterType]: {
            ...state.filters[action.payload.filterType],
            [action.payload.filterKey]: {
              ...state.filters[action.payload.filterType][action.payload.filterKey],
              active: action.payload.value,
            },
          },
        },
      };
    
    case SET_PARKING_FILTER_PROPERTY_TYPES:
      return {
        ...state,
        filters: {
          ...state.filters,
          propertyTypes: {
            ...state.filters.propertyTypes,
            activePropertyType: action.payload,
          },
        },
      };
    case SET_SEARCH_MAP_CENTER:
      return { 
        ...state, 
        map: {
          ...state.map,
          center: action.payload,
        },
      };
    
    case SET_SEARCH_TYPE:
      return { ...state, searchType: action.payload };
    
    case SET_SEARCH_MAP_FURTHEST_POINT:
      return { ...state, map: { ...state.map, furthestPoint: action.payload }};

    case SET_SEARCH_CURRENT_RADIUS:
      return { ...state, map: { ...state.map, currentRadius: action.payload }};
    
    case SET_AVAILABILITY_CANCEL_REF:
      return { ...state, availabilityCheckCancelRef: action.payload };
    default:
      return state;
  }
};

export default reducer;