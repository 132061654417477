import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FilterButton } from '../ParkingDiscoveryFilterContainer/ParkingDiscoveryFilter/ParkingDiscoveryFilters.style';
import { setSelectedParkingSpace } from '../../../redux/parkingDiscoveryStore/selectedParkingSpace/actions';
import { setOpenModal } from '../../../redux/parkingDiscoveryStore/favoriteRegion/actions';

class ParkingListNotFound extends Component {
  openModalOrRedirectToLogin(){
    if(this.props.authentication_token){
      this.props.setOpenModal(true)
      
    }else{
      if (typeof window !== 'undefined') {
        window.location.href = "/users/sign_in";
      }
    }
  }
  render() {
    return (
      <div className='section pt-2 pb-2 text-center grey-color' style={{borderBottom: "1px solid rgb(217, 225, 233)"}} >
          <h5>{this.props.translations.not_found_what_you_need}</h5>
          <FilterButton
            ref={this.target}
            className="btn btn-link"
            onClick={() => {
                this.openModalOrRedirectToLogin()
            }}>
          <span>
              <i className="fas fa-sharp mr-2 fa-flip fa-light fa-bell fa-sm "  aria-hidden="true"></i>
          </span>
          {this.props.translations.watch_this_area}
          </FilterButton>
          <h5 className='mt-3'>{this.props.translations.get_notifiied_listings}</h5>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { translations, openModal } = state;
  return {
    translations,
    openModal
  };
};

export default connect(mapStateToProps, { 
  setOpenModal,
})(ParkingListNotFound); 