import 'react-dates/initialize';
import React from 'react';
import { SingleDatePicker } from 'react-dates';
import PropTypes from 'prop-types';
import * as memoize from 'fast-memoize';

import TimeSelect from '../TimeSelect/TimeSelect';
import {
  SearchBarWrapper,
  SearchInputWrapper,
  DatesWrapper,
  SubmitButtonWrapper,
  PeriodButtons,
  PeriodRadioButton,
  ActiveDatesContainer,
} from './ParkingSearchBar.style';


const HOURLY = 'HOURLY';
const MONTHLY = 'MONTHLY';
const LATEST_TIME = '2345';

class ParkingSearchBar extends React.Component {
  constructor(props) {
    super(props);

    // hold ref to the text input responsible for the google places autocomplete
    this.googlePlacesRef = React.createRef();
    
    // memoized func for startDate calendar
    this.startDateMemo = memoize(this.isOutSideRangeStart);
    // memoized func for endDate calendar
    this.endDateMemo = memoize(this.isOutSideRangeEnd);
  } // end constructor

  isOutSideRangeStart = (day, currentDateTime, period) => {
    // add 12 hours to day so it can be noon. bug with changing day fix
    const isToday = day.format('DDMMYYYY') === currentDateTime.date.format('DDMMYYYY');
    if (
      period === 'MONTHLY' &&
      isToday
    ) {
      return true;
    }

    day.hour(12);
    
    if (
      currentDateTime.date.diff(day, 'days') >= 1 || (
        isToday &&
        currentDateTime.time === LATEST_TIME
      )                    
    ) {
      return true;
    }

    return false;
  }

  isOutSideRangeEnd = (day, startTime, startDate) => {
      // add 12 hours to day so it can be noon. bug with changing day fix
      day.hour(12);

      const isFinalTime = startTime === LATEST_TIME;

      if( 
        !isFinalTime &&
        startDate.diff(day, 'days') < 1
      ) {
        return false;
      }

      if( 
        isFinalTime &&
        startDate.diff(day, 'days', true) < 0
      ) {
        return false;
      }
      
      return true;
  }

  componentDidMount = () => {
    this.setupGooglePlaces();
  } // end componentDidMount

  componentWillUnmount = () => {
    this.clearGooglePlaces();
  } // end componentWillUnmount

  setupGooglePlaces = () => {
    const region = document.querySelector('body').getAttribute('data-region');
    const googleAutocomplete = new google.maps.places.Autocomplete(
      this.googlePlacesRef.current, 
      {
        types: ['geocode'], 
        componentRestrictions: {
          country: region,
        },
      },
    );
    // run this whenever a users selects a different location
    googleAutocomplete.addListener('place_changed', () => {
      const place = googleAutocomplete.getPlace();

      // if no places from autocomplte, just return.
      if(!place.geometry) {
        return;
      } // end if
      // handle the locations change.
      this.props.onLocationChange({
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address_components: place.address_components
      });

      // fire on submit
      this.props.onSubmit();
    });
  } // end setupGooglePlaces

  clearGooglePlaces = () => {
    // clean up google autocomplete
    google.maps.event.clearInstanceListeners(this.googlePlacesRef.current);

    // remove the dom nodes googleplace adds
    const autocompleteBox = document.querySelector('.pac-container')
    if(autocompleteBox) autocompleteBox.parentNode.removeChild(autocompleteBox);
  } // end clearGooglePlaces

  // handle address not retrived from google places
  onAddressChange = () => {
    this.props.onLocationChange({
      address: '' ,
      lat: 0,
      lng: 0
    });
  } // end onAddressChange

  handleSearchInputKeyup = (e) => {
    if (event.keyCode === 13) {
      const firstSuggestionNode = document.querySelector('.pac-container .pac-item');
      const firstSuggestion = firstSuggestionNode && (
        Array.prototype.slice.call(firstSuggestionNode.childNodes)
          .map(e => e.textContent)
          .filter(e => !!e)
          .join(', ')
      );

      if (firstSuggestion) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: firstSuggestion }, (res, status) => {
          if (status === 'OK') {
            const changePayload = {
              address: firstSuggestion,
              lat: res[0].geometry.location.lat(),
              lng: res[0].geometry.location.lng()
            };
            // fire on change
            this.props.onLocationChange(changePayload);

            // update search input
            this.googlePlacesRef.current.value = firstSuggestion;

            // fire on submit
            this.props.onSubmit();
          } // end if 
        });
      }
    }
  } // end handleSearchInputKeyup

  endDateOutsideRange = () => {
    if (!this.props.dates.startDate) return {};

    
    return {
      isDayBlocked: (day) => {  
        return this.endDateMemo(day, this.props.dates.startTime, this.props.dates.startDate);
      },
    } // end return
  } // endDateOutsideRange 

  // display the following format: Today at HH:MM || Do MMM at HH:MM
  displayFormat = (dateToCompare, timeToCompare) => {
    const date = (
      dateToCompare.format('DDMMYYYY') === 
      this.props.currentDateTime.date.format('DDMMYYYY')
    ) ? (
      `[${this.props.translations.today.charAt(0).toUpperCase() + this.props.translations.today.slice(1)}]`
    ) : 'Do MMM';

    const hour = timeToCompare.slice(0, 2);
    const minutes = timeToCompare.slice(2);
    const time = `${this.props.translations.at} ${hour}:${minutes}`;

    return `${date} [${timeToCompare && this.props.period !== MONTHLY ? time : ''}]`;
  } // end displayFormat

  renderPeriod = () => {
    return (
      <React.Fragment>
        <PeriodRadioButton active={this.props.period === HOURLY} className="text-capitalize">
          <input 
            type="radio" 
            name="parking-search-bar-period" 
            onChange={() => this.props.onPeriodChange(HOURLY) }
            checked={this.props.period === HOURLY}
            value={HOURLY} 
          /> 
          {this.props.translations.hourly}
        </PeriodRadioButton>

        <PeriodRadioButton active={this.props.period === MONTHLY} className="text-capitalize">
          <input 
            type="radio" 
            name="parking-search-bar-period" 
            onChange={() => this.props.onPeriodChange(MONTHLY) }
            checked={this.props.period === MONTHLY}
            value={MONTHLY} 
          /> {this.props.translations.monthly}
        </PeriodRadioButton>
      </React.Fragment>
    );
  } // end renderPeriod
  
  renderSearchInput = () => {
    return (
      <React.Fragment>
        <i className="fas fa-map-marker-alt"></i>
        <input 
        style={{ width: '100%', height: '100%' }}
        type="text" 
        ref={this.googlePlacesRef}
        placeholder={this.props.translations.parking_search_input_placeholder}
        onChange={this.onAddressChange}
        defaultValue={this.props.location.address || ''}
        onKeyUp={this.handleSearchInputKeyup}
      />
      </React.Fragment>
    );
  } // end renderSearchInput
  
  renderStartDate = () => {
    
    return (
      <SingleDatePicker
        date={this.props.dates.startDate}
        onDateChange={date => this.props.onDateChange('startDate', date.hour(12))} // PropTypes.func.isRequired
        focused={this.props.startDateFocused} // PropTypes.bool
        onFocusChange={({ focused }) => {
          this.props.onFocusChange('startDateFocused', focused);
          this.props.onCalendarToggle(focused);
        }} // PropTypes.func.isRequired
        id="parking-search-bar-start-date" // PropTypes.string.isRequired,
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        keepOpenOnDateSelect
        block
        placeholder="Select a date and time"
        readOnly
        small
        withPortal={this.props.isMobile}
        showDefaultInputIcon
        isDayBlocked={(day) => {
          return this.startDateMemo(day, this.props.currentDateTime, this.props.period);
        }}
        displayFormat={this.displayFormat(this.props.dates.startDate, this.props.dates.startTime)}
        renderCalendarInfo={() => {
          if (!this.props.startDateFocused) return null;
          
          return (
            <ActiveDatesContainer column wrap="nowrap" justifyContent="flex-start" alignItems="stretch" onClick={e => e.stopPropagation()}>
              {
                this.props.period !== MONTHLY &&
                <TimeSelect
                  value={this.props.dates.startTime}
                  onChange={(time) => this.props.onDateChange('startTime', time)}
                  startTime={this.props.currentDateTime.date.format('DD-MM-YYYY') === this.props.dates.startDate.format('DD-MM-YYYY') ? this.props.currentDateTime.time : null}
                />
              }

              <button 
                className="btn btn-primary text-capitalize"
                onClick={() => this.props.onFocusChange('startDateFocused', false, { focusOnEndDate: true })}
              >
                {this.props.translations.done}
              </button>
            </ActiveDatesContainer>
          );
        }}
      />  
    );
  } // end renderStartDate
  
  renderEndDate = () => {
    return (
      <SingleDatePicker
        date={this.props.dates.endDate}
        onDateChange={date => this.props.onDateChange('endDate', date.hour(12))} // PropTypes.func.isRequired
        focused={this.props.endDateFocused} // PropTypes.bool
        onFocusChange={({ focused }) => {
          this.props.onFocusChange('endDateFocused', focused);
          this.props.onCalendarToggle(focused);
        }}
        id="parking-search-bar-end-date" // PropTypes.string.isRequired,
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        keepOpenOnDateSelect
        placeholder="Select a date and time"
        readOnly
        small
        withPortal={this.props.isMobile}
        showDefaultInputIcon
        displayFormat={this.displayFormat(this.props.dates.endDate, this.props.dates.endTime)}
        { ...this.endDateOutsideRange() }
        block
        renderCalendarInfo={() => {
          // if(!this.props.endDateFocused) return null;
          return (
            <ActiveDatesContainer column wrap="nowrap" justifyContent="flex-start" alignItems="stretch" onClick={e => e.stopPropagation()}>
              <TimeSelect 
                value={this.props.dates.endTime}
                onChange={(time) => this.props.onDateChange('endTime', time)} 
                startTime={this.props.isSameDay ? this.props.dates.startTime : null}
              />    
              <button 
                className="btn btn-primary text-capitalize"
                onClick={() => { this.props.onFocusChange('endDateFocused', false) }}
              >
                {this.props.translations.done}
              </button>
            </ActiveDatesContainer>
          );
        }}
      />
    );
  } // end renderEndDate

  render() {
    return (
      <SearchBarWrapper nowrap justifyContent="flex-start" align-items="center">
        <PeriodButtons 
          wrap="nowrap" justifyContent="space-between" alignItems="stretch"
          className="period-container"
        >
          {this.renderPeriod()}
        </PeriodButtons>
        <SearchInputWrapper flex={1} className="search-container">
          {this.renderSearchInput()}     
        </SearchInputWrapper>

        {
          this.props.provideDates && 
          <DatesWrapper className="dates-container" wrap="nowrap" justifyContent="space-between" alignItems="stretch">
            {this.renderStartDate()}
            {this.props.period !== MONTHLY && <span>{this.props.translations.to}</span>}
            {this.props.period !== MONTHLY && this.renderEndDate()}
          </DatesWrapper>
        }

        <SubmitButtonWrapper 
          className="btn btn-primary submit-container text-capitalize"
          onClick={this.props.onSubmit}
        >
          {this.props.translations.search}
        </SubmitButtonWrapper>
      </SearchBarWrapper>
    );
  } // end render
}; // end ParkingSearchBar

ParkingSearchBar.propTypes = {
  period: PropTypes.oneOf([HOURLY, MONTHLY]).isRequired,
  provideDates: PropTypes.bool.isRequired,
  isSameDay: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,

  location: PropTypes.shape({
    address: PropTypes.string.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,

  dates: PropTypes.shape({
    startDate: PropTypes.object,
    startTime: PropTypes.string.isRequired,
    endDate: PropTypes.object,
    endTime: PropTypes.string.isRequired,
  }).isRequired,
}; // end propTypes

export default ParkingSearchBar;