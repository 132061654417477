import React, { Component } from 'react';
import { connect } from 'react-redux';

import ParkingDetailPopupMobile from './ParkingDetailPopupMobile/ParkingDetailPopupMobile';

class ParkingDetailPopupMobileContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetailsPopup: false,
    };
  }

  handleClick = () => {
    this.setState({ showDetailsPopup: true });
  } // end handleClick

  handlePopupClose = () => {
    this.setState({ showDetailsPopup: false });
  } // end handlePopupClose


  render() {
    if (!this.props.selectedParkingSpace)
      return null;

    return (
      <ParkingDetailPopupMobile 
        parkingSpace={this.props.selectedParkingSpace}
        showDetailsPopup={this.state.showDetailsPopup}
        onClick={this.handleClick}
        onPopupClose={this.handlePopupClose}
        facilities={this.props.facilities}
        translations={this.props.translations}
        searchOptions={this.props.searchBar}
      />
    );
  }
} // end ParkingDetailPopupMobileContainer

const mapStateToProps = ({ 
  selectedParkingSpace, 
  searchConfig: { filters: { facilities }, searchBar}, 
  translations,
}) => ({
  selectedParkingSpace,
  facilities,
  translations,
  searchBar,
});

export default connect(mapStateToProps)(ParkingDetailPopupMobileContainer);