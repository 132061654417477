import styled, { css, keyframes } from 'styled-components';

export const FiltersWrapper = styled.section`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;

  min-height: 90px;
  z-index: 4;
  padding: 14px 20px 12px 14px;
  background: #fff;

  border-bottom: 1px solid #E5E5E5;

  & .left {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;

    flex: 1;
    margin-right: 25px;

    span {
      font-size: 0.9em;
      font-weight: bold;
    }

    label {
      align-self: stretch;
    }
  }

  & .right {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;

    span {
      font-size: 0.9em;
    }
  }
`;

export const FilterButton = styled.button`
  border: 1px solid HSLA(213, 97%, 53%, 1.00);
  border-radius: 25px;
  text-decoration: none !important;

  &:hover {
    border: 1px solid HSLA(213, 97%, 53%, 1.00);
  }

  ${activeButtonLogic}
`

function activeButtonLogic({ active }) {
  if (active) {
    return css`
      position: relative;
      background-color: HSLA(213, 97%, 53%, 1.00);
      color: white;

      &:hover {
        border: 1px solid HSLA(213, 97%, 53%, 1.00);
        background-color: white;
        color: HSLA(213, 97%, 53%, 1.00);
      }

      &:after {
        content: '';
        width: 0.5em;
        height: 0.5em;
        background: red;
        position: absolute;
        top: 2px;
        right: 2px;
        border-radius: 25px;
      }
    `;
  }
}