import Modal from 'react-modal';
import React from 'react';
import ReactDOM from 'react-dom';

const customsStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 'none',
    marginRight: '-50%',
    borderStyle: 'solid',
    borderWidth: '3px 1px 1px',
    borderTopColor: '#007AFF',
    borderBottomColor: 'rgb(204, 204, 204)',
    borderLeftColor: 'rgb(204, 204, 204)',
    borderRightColor: 'rgb(204, 204, 204)',
    borderRadius: '0.3rem',
    transform: 'translate(-50%, -50%)',
  },
};


import { connect } from 'react-redux';

function FavouriteLocationModal(props) {
  const {save_this_search, email_when_new_property, push_notification_when_new_property, save_search} = props.translations;


  return (
    <Modal
        isOpen={props.openModal}
        style={customsStyles}
      >
      <div className="modal-header d-flex justify-content-between" style={{borderBottomColor: '#F0F1F2'}}>
        <button aria-label="Close" className="close order-1" data-dismiss="modal" type="button" onClick={()=>props.cancel()}>
          <span aria-hidden="true">×</span>
        </button>
        <h5 className="modal-title">
          {save_this_search}
        </h5>
      </div>
      <div className='modal-body'>
        <label 
          className="check-box-1 mt-4 mb-4"
          style={{display: 'flex'}}
        >
          <input 
            type="checkbox" 
            onChange={()=> props.onPushNotificationInputChange()}
            checked={props.sendNotification}
          />
          <div className="check-1">
            <i className="fas fa-check"></i>
          </div>
          <span className="pl-3">
            {push_notification_when_new_property}
          </span>
        </label>

        <label 
          className="check-box-1 mt-4 mb-4"
          style={{display: 'flex'}}
        >
          
          <input 
            type="checkbox" 
            onChange={()=> props.onEmailInputChange()}
            checked={props.sendEmail}
          />
          <div className="check-1">
            <i className="fas fa-check"></i>
          </div>
          <span className="pl-3">
            {email_when_new_property}
          </span>
        </label>
        <div className="row justify-content-center">
          <button className="btn btn-outline-primary " onClick={()=> props.cancel()}>{props.translations.cancel}</button>
          <button className="btn btn-primary  ml-4 " onClick={()=> props.subscribe()}>{save_search}</button>
        </div>
      </div>
    </Modal>
  );
}


const mapStateToProps = function(state) {
  return {
    openModal: state.openModal
  }
}

export default connect(mapStateToProps)(FavouriteLocationModal);