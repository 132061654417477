import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from './FacilityIcon.style';

const FacilityIcon = ({ src, style }) => {
  return <Icon 
    src={src}
    style={style}
  />;
};

FacilityIcon.propTypes = {
  style: PropTypes.object,
  src: PropTypes.string.isRequired,
};

FacilityIcon.defaultProps = {
  style: {},
};

export default FacilityIcon;