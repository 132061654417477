import styled, { css } from 'styled-components';

const navBarHeight = 61;
const navBarHeightMobile = 50;
const sideNavWidth = 25; // in percentage

const tabletSize = 992;


export const Wrapper = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;

  position: relative;
  height: calc(100vh - ${navBarHeight}px);

  /* tablet styling */
  @media (max-width: ${tabletSize}px) {
    height: calc(100vh - ${navBarHeightMobile}px);
  }
`;


export const HeaderWrapper = styled.header`
  position: relative;
  z-index: 2;
  padding: 15px 20px;
  border-bottom: 1px solid #F0F1F2;
  background-color: white;

  @media (max-width: ${tabletSize}px) {
    padding: 0;
    border: 0;
    z-index: initial;
  }
`;


export const MainWrapper = styled.main`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;

  height: 100%;

  /* tablet styling */
  @media (max-width: ${tabletSize}px) {
    flex-flow: column;
  }

`;


export const SidenavWrapper = styled.aside`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;

  width: ${sideNavWidth}%;
  min-width: 330px;
  height: 100%;
  position: relative;
  z-index: 1;

  background-color: white;
  box-shadow: 1px 2px 3px 0 rgba(33,33,33,.078);

  border-right: 1px solid #ccc;

  /* tablet styling */
  @media (max-width: ${tabletSize}px) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    transform: ${({ mobileActive }) => mobileActive ? 'translateX(0)' : 'translateX(-100%)'};
    opacity: ${({ mobileActive }) => mobileActive ? '1' : '0'};
    transition: 0.2s;
    z-index: 2 !important;
  }
`;


export const MapWrapper = styled.section`
  position: relative;
  width: ${100 - sideNavWidth}%;

  @media (max-width: ${tabletSize}px) {
    flex: 1;
    width: 100%;
  }
`;
