import React from 'react';
import { ButtonWrapper } from './ParkingListViewSwitcher.style.js'

const ParkingListViewSwitcher = ({ activeListView, onChange }) => {
  return (
    <ButtonWrapper onClick={onChange}>
      {
        activeListView && 
        <i className="fas fa-map fa-2x"></i>
      }

      {
        !activeListView && 
        <i className="fas fa-list-ul fa-2x"></i>
      }
    </ButtonWrapper>
  )
};

export default ParkingListViewSwitcher;