import React from 'react';
import MediaQuery from 'react-responsive';

import ParkingDiscoveryWrapper from './ParkingDiscoveryWrapper/ParkingDiscoveryWrapper';
import ParkingSearchBarContainer from '../../components/ParkingSearchBarContainer/ParkingSearchBarContainer';
import ParkingListContainer from './ParkingListContainer/ParkingListContainer';
import ParkingDiscoveryFilterContainer from './ParkingDiscoveryFilterContainer/ParkingDiscoveryFilterContainer';
import ParkingDetailPopupContainer from './ParkingDetailPopupContainer/ParkingDetailPopupContainer';
import ParkingDiscoveryMapContainer from './ParkingDiscoveryMap/ParkingDiscoveryMapContainer';
import ParkingDiscoveryFilterPopupContainer from './ParkingDiscoveryFilterPopupContainer/ParkingDiscoveryFilterPopupContainer';
import ParkingDetailPopupMobileContainer from './ParkingDetailPopupMobileContainer/ParkingDetailPopupMobileContainer';
import ParkingMobileSearchButtonContainer from './ParkingMobileSearchButtonContainer/ParkingMobileSearchButtonContainer';
import ParkingListViewSwitcherContainer from './ParkingListViewSwitcherContainer/ParkingListViewSwitcherContainer';


const ParkingDiscovery = (props) => {

  const renderHeader = () => (
    <React.Fragment>
      {
        props.isMobile &&
        <ParkingListViewSwitcherContainer />
      }
      {
        !props.isMobile &&
        <ParkingSearchBarContainer 
          provideDates
          location={props.location}
          period={props.period}
          dates={props.searchBarDates}
          onChange={(searchBarState) => props.onSearchBarChange(searchBarState)} 
          onSubmit={(searchBarState) => props.onSearchBarSubmit(searchBarState)}
          translations={props.translations}
          onCalendarToggle={(active) => {
            if (props.selectedParkingSpace) {
              props.setSelectedParkingSpace(null);
            }
          }}
        />
      }
    </React.Fragment>
  ); // end renderHeader

  const renderSidenav = () => (
    <React.Fragment>
      {
        (
          (props.isMobile && props.mobileListViewActive) ||
          (!props.isMobile)
        ) &&
        <React.Fragment>
          <ParkingDiscoveryFilterContainer authentication_token={props.authentication_token} />
          <ParkingDetailPopupContainer />
          <ParkingDiscoveryFilterPopupContainer />  
        </React.Fragment>
      }
      <ParkingListContainer authentication_token={props.authentication_token} />
    </React.Fragment>
  ); // end renderSidenav

  const renderMap = () => {
    return (
      <React.Fragment>
        <ParkingDiscoveryMapContainer/>
        {
          !props.mobileListViewActive &&
          <React.Fragment>
            <ParkingMobileSearchButtonContainer 
              dates={props.searchBarDates}
              onChange={(searchBarState) => props.onSearchBarChange(searchBarState)} 
              onSubmit={(searchBarState) => props.onSearchBarSubmit(searchBarState)}
            />
            <ParkingDetailPopupMobileContainer />
          </React.Fragment>
        }
      </React.Fragment>
    );
  }; // end renderMap
  
  return (
    <MediaQuery 
      maxWidth={992}
      onChange={props.onMobileChange}
    >
      {(matched) => {
        return (
          <ParkingDiscoveryWrapper 
            onMapClick={props.onMapClick}
            isMobile={props.isMobile} 
            mobileListViewActive={props.mobileListViewActive}>
            {{
              header: renderHeader(),
              sidenav: renderSidenav(),
              map: renderMap(),
            }}
          </ParkingDiscoveryWrapper>
        )
      }}
    </MediaQuery>
    
  );
}; // end ParkingDiscovery

export default ParkingDiscovery;