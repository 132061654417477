import React, {Component} from 'react';
import axios from 'axios';
import { OverlayTrigger, Overlay, Tooltip, Button } from 'react-bootstrap';
import { FilterButton } from './ParkingDiscoveryFilters.style';
import FavouriteLocationModal from './FavouriteLocationModal'
import { setOpenModal } from '../../../../redux/parkingDiscoveryStore/favoriteRegion/actions';
import { connect } from 'react-redux';

class FavouriteLocationButton extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      subscribed: null,
      tooltipShow: false,
      sendEmail: true, 
      sendNotification: true,
      tooltipContent: ''
    };
    this.target = React.createRef(null);
  }

  componentWillReceiveProps(props){
    if(props.authentication_token && props.lat > 0){
      let result = '?' + new URLSearchParams(this.areaBody(props)).toString();
      axios.get(`/api/v1_2/watched_regions/check.json${result}`,
      {
        headers: {
          'Authorization': `Basic ${this.props.authentication_token}` 
        }
      }).then((res)=>{
        
        this.setState({subscribed: true})
      }).catch((error)=>{
        this.setState({subscribed: false})
      })
    }
  }

  handleClickOutside(){
    this.setState({tooltipShow: false})
  }

  componentDidMount(){
    document.addEventListener('click', () => this.handleClickOutside());
    if(this.props.authentication_token){
      let result = '?' + new URLSearchParams(this.areaBody()).toString();
      axios.get(`/api/v1_2/watched_regions/check.json${result}`,
      {
        headers: {
          'Authorization': `Basic ${this.props.authentication_token}` 
        }
      }).then((res)=>{
        this.setState({subscribed: true})
      }).catch((error)=>{
        this.setState({subscribed: false})
      })
    }
  }

  showModal(){
    if(this.state.subscribed)
    return this.subscribeForLocation(!this.state.subscribed)
    this.props.setOpenModal(true)
  }

  subscribeForLocation(subscribed){
    if(this.props.authentication_token){
      axios.post(`/api/v1_2/watched_regions.json`,this.areaBody(),
      {
        headers: {
          'Authorization': `Basic ${this.props.authentication_token}` 
        }
      }).then((res)=>{
        this.setState({tooltipContent: `You'll be notified when new listings are added in ${res.data.address}` })
        this.setState({tooltipShow: res.status == 200, subscribed: subscribed }) 
      }).catch((error)=>{
        this.setState({subscribed: false})
      })
    }
    this.props.setOpenModal(false)
  }

  areaBody(props=this.props){
    return {
      region: document.querySelector('body').getAttribute('data-region'),
      address: props.address,
      lat: props.lat,
      lng: props.lng,
      send_email: this.state.sendEmail,
      send_notification: this.state.sendNotification,
      authentication_token: props.authentication_token
    }
  }

  sendMailForNotifications(){
    this.setState({sendEmail: !this.state.sendEmail})
  }

  render(){
    if(this.props.authentication_token && this.state.subscribed!=null){
      return (
        <div className={"right ml-1"}>
          <FavouriteLocationModal 
            onEmailInputChange={() =>this.sendMailForNotifications()}
            onPushNotificationInputChange={() => this.setState({sendNotification: !this.state.sendNotification})}
            sendEmail={this.state.sendEmail}
            sendNotification={this.state.sendNotification}
            cancel={() => this.props.setOpenModal(false) }
            translations={this.props.translations}
            subscribe={()=> this.subscribeForLocation(!this.state.subscribed)}
          />
          <span></span>
          <FilterButton
            ref={this.target}
            active={this.state.subscribed}
            className="btn btn-link text-capitalize"
            onClick={() => {
                this.showModal()
            }}>

            <span >
              <i 
                ref={this.target}
                className="fas fa-sharp mr-2 fa-flip fa-light fa-bell fa-xs"  aria-hidden="true"></i>
            </span>
            <span 
            ref={this.target}
            >{this.props.translations.save}</span>

            <Overlay target={this.target.current} show={this.state.tooltipShow} placement="right">
              {(props) => (
                <Tooltip  {...props}>
                  {
                    this.state.tooltipContent
                  }
                </Tooltip>
              )}
            </Overlay>
          </FilterButton>
        </div >
      )
    }
    return (<></>) 
  }
};

const mapStateToProps = (state) => {
  const { translations, openModal } = state;
  return {
    translations,
    openModal
  };
};
export default connect(mapStateToProps, { 
  setOpenModal,
})(FavouriteLocationButton); 