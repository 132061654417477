import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  max-width: 450px;
  width: 100%;

  & > * {
    background: white;
    height: 120px;
    box-shadow: 1px 2px 3px 0 rgba(33,33,33,.078);
    border: 1px solid #ccc;
  }

  display: none;
  @media (max-width: 992px) {
    display: block;
    padding: 0px .5em .5em .5em;
    height: 125px;

    & > * {
      align-items: stretch;
      border-radius: 6px;

      & > *:first-child {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }
`;

export const PopupWrapper = styled.div`
    & > * {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: 0;

      max-width: 450px;
      width: 100%;
      border-radius: 0;
      box-shadow: none;

      z-index: 3;
    }

    display: none;
    @media (max-width: 992px) {
      display: block;
    }
`;
