import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';

import ParkingSearchBar from './ParkingSearchBar';

class ParkingSearchBarContainer extends React.Component {
  static HOURLY = 'HOURLY'
  static MONTHLY = 'MONTHLY'

  static PERIOD_KEY = 'period'
  static LOCATION_KEY = 'location'
  static START_DATE_KEY = 'startDate'
  static END_DATE_KEY = 'endDate'
  static START_TIME_KEY = 'startTime'
  static END_TIME_KEY = 'endTime'

  constructor(props) {
    super(props);

    const currentDateTime = moment(new Date());
    // initilize state.
    this.state = {
      period: props.period || 'HOURLY',

      location: {
        address: (props.location && props.location.address) || '',
        lat: (props.location && props.location.lat) || 0,
        lng: (props.location && props.location.lng) || 0,
      },

      dates: {
        startDate: props.dates.startDate.set({ hour: 12, minute: 0}), // moment object
        startTime: props.dates.startTime,
        endDate: props.dates.endDate.set({ hour: 12, minute: 0}), // moment object
        endTime: props.dates.endTime,
      },

      startDateFocused: false,
      endDateFocused: false,

      isSameDay: this.isSameDay(props.dates.startDate, props.dates.endDate),

      currentDateTime: {
        date: currentDateTime.clone().set({ hour: '12', 'minute': 0 }),
        time: currentDateTime.minute() >= 45 ? currentDateTime.format('HH45') : currentDateTime.format('HH00'),
      },
    }; // end this.state

    this.initLocationFromProps = false;
    this.initPeriodFromProps = false;
  } // end constructor

  onCalendarToggle = (active) => {
    if (this.props.onCalendarToggle) {
      this.props.onCalendarToggle(active);
    }
  }


  componentDidUpdate = (prevProps, prevState) => {
    const isSameDay = this.isSameDay();
    if (isSameDay !== this.state.isSameDay)
      this.setState({ isSameDay });

    // initilize location from props if specified.
    if (!this.initLocationFromProps && this.props.location.address) {
      this.initLocationFromProps = true;
      this.setState({ location: this.props.location }, () => {
        this.props.onChange({ type: 'LOCATION_INIT', payload: this.state });
      });
    }

    // initlize period from props if specified.
    if (!this.initPeriodFromProps && this.props.period) {
      this.initPeriodFromProps = true;
      this.setState({ period: this.props.period }, () => {
        this.props.onChange({ type: 'PERIOD_INIT', payload: this.state });
      });
    } // end if
  } // end componentDidUpdate


  handlePeriodChange = (newPeriod) => {
    this.initPeriodFromProps = true;

    // change start date if today to be next day if current start date is today
    let newStartDate = {};
    if (
      newPeriod === 'MONTHLY' &&
      this.isSameDay(this.state.dates.startDate, this.state.currentDateTime.date)
    ) {
      let newEndDate = {};
      if (this.isSameDay(this.state.dates.startDate, this.state.dates.endDate)) {
        newEndDate = {
          endDate: this.state.dates.startDate.clone().add(2, 'days'),
        };
      }

      newStartDate = {
        dates: {
          ...this.state.dates,
          ...newEndDate,
          startDate: this.state.dates.startDate.clone().add(1, 'days'),
          endTime: '2345',
        }
      };

    }

    this.setState({ period: newPeriod, ...newStartDate }, () => {
      // call top level onChange here, with the changing key.
      this.props.onChange({ type: ParkingSearchBarContainer.PERIOD_KEY, payload: this.state });
    });
  } // end handlePeriodChange


  // handle updating and validating for date change.
  handleDateChange = (key, value) => {
    // default newState
    let newState = ({ dates }) => ({ dates: { ...dates, [key]: value }});

    // validation on 'startTime'
    if(
      key === 'startTime' &&
      this.isSameDay()
    ) {
      // set end date to null if trying to travel back in time ;P
      if (parseInt(value) >= parseInt(this.state.dates.endTime)) {
        const newEndTime = moment(new Date())
          .hour(value.slice(0, 2))
          .minute(value.slice(2))
          .add(15, 'minute')
          .format('HHmm');

        newState = ({ dates }) => ({ dates: { ...dates,  [key]: value, endTime: newEndTime, }});
      } // end inner if

      // if it's 23:45, switch to next day in the calendar.
      if(value === '2345') {
        const newEndDate = this.state.dates.startDate.clone();
        const newEndTime = moment(new Date())
          .hour(value.slice(0, 2))
          .minute(value.slice(2))
          .add(15, 'minute')
          .format('HHmm');
        // add one day
        newEndDate.add(1, 'day');

        // create a new state, with the next day
        newState = ({ dates }) => ({
          dates: {
            ...dates, [key]: value, endTime: newEndTime, endDate: newEndDate,
          },
        });
      } // end inner if
    } // end if

    // start date validations
    if (
      key === 'startDate'
    ) {
      let changedEndDate = {};
      let changeEndTime = {};

      // if startDate is the same day or greater than end date.
      if (value.diff(this.state.dates.endDate) >= 0) {
        // new end date: if startTime is 2330 make end date on day ahead, else leave it the same day
        changedEndDate.endDate = this.state.dates.startTime === '2345' ? value.clone().add(1, 'day') : value.clone();

        // if the startTime > than the endTime, set endTime to null
        if (parseInt(this.state.dates.startTime) >= parseInt(this.state.dates.endTime))
          changeEndTime.endTime = moment(new Date())
            .hour(this.state.dates.startTime.slice(0, 2))
            .minute(this.state.dates.startTime.slice(2))
            .add(15, 'minute')
            .format('HHmm');
      } // end if


      // if the start time is less than the current time, update the start time to the currentTime.
      let changeStartTime = {};
      if (
        this.isSameDay(value, this.state.currentDateTime.date) &&
        parseInt(this.state.dates.startTime) <= parseInt(this.state.currentDateTime.time)
      ) {
        changeStartTime.startTime = this.state.currentDateTime.date.clone().set({
          hour: this.state.currentDateTime.time.slice(0,2),
          minute: this.state.currentDateTime.time.slice(2),
          second: 0,
          millisecond: 0,
        }).add(15, 'minute').format('HHmm');
      } // end if

      // state updater function.
      newState = ({ dates }) => ({
        dates: {
          ...dates,
          [key]: value,
          ...changedEndDate,
          ...changeEndTime,
          ...changeStartTime,
        }
      });
    } // end startDate if

    // end date validations.
    if (
      key === 'endDate' &&
      this.isSameDay(this.state.dates.startDate, value) &&
      parseInt(this.state.dates.startTime) >= parseInt(this.state.dates.endTime)
    ) {
      const newEndTime = moment(new Date())
      .hour(this.state.dates.startTime.slice(0, 2))
      .minute(this.state.dates.startTime.slice(2))
      .add(15, 'minute')
      .format('HHmm');
      newState = ({ dates }) => ({ dates: { ...dates, [key]: value, endTime: newEndTime, }});
    } // end endDate if

    // set the new state.
    this.setState(newState, () => {
      // call top level onChange here, with the changing key.
      this.props.onChange({ type: key, payload: this.state });
    }); // end setState
  } // end handleDateChange


  isSameDay = (date1, date2) => {
    if (date1 && date2)
      return date1.format('DDMMYYYY') === date2.format('DDMMYYYY');

    // if dates haven't been initilzed yet
    if(!this.state.dates.startDate || !this.state.dates.endDate) return false;


    return ( this.state.dates.startDate.format('DDMMYYYY') ===
      this.state.dates.endDate.format('DDMMYYYY')
    );
  } // end isSameDay


  handleLocationChange = (newLocation) => {
    this.setState({ location: newLocation }, () => {
      // call top level onChange here, with the changing key.
      this.props.onChange({ type: ParkingSearchBarContainer.LOCATION_KEY, payload: this.state });
    });
  }


  handleFocusChange = (key, value, options = {}) => {
    // logic to focus on end date if done is tiggered on start date.
    let focusOnEndDate = {};
    if (!this.props.isMobile && options.focusOnEndDate) {
      focusOnEndDate = { endDateFocused: true };
    }

    this.setState({
      [key]: value,
      ...focusOnEndDate
    }, () => {
      // call some higher level api.
    });
  } // end handleFocusChange


  // trigger when user clicks the submit button
  handleSubmit = () => {
    this.props.onSubmit(this.state);
  } // end handleSubmit


  render () {
    return (
      <ParkingSearchBar
        { ...this.state }
        provideDates={this.props.provideDates}
        onPeriodChange={this.handlePeriodChange}
        onDateChange={this.handleDateChange}
        onLocationChange={this.handleLocationChange}
        onFocusChange={this.handleFocusChange}
        onSubmit={() => this.handleSubmit() }
        currentDateTime={this.state.currentDateTime}
        isMobile={this.props.isMobile || false}
        onCalendarToggle={this.onCalendarToggle}
        translations={this.props.translations}
      />
    );
  }
}

ParkingSearchBarContainer.propTypes = {
  period: PropTypes.oneOf([ParkingSearchBarContainer.HOURLY, ParkingSearchBarContainer.MONTHLY]),

  location: PropTypes.shape({
    address: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),

  dates: PropTypes.shape({
    startDate: PropTypes.object,
    startTime: PropTypes.string.isRequired,
    endDate: PropTypes.object,
    endTime: PropTypes.string.isRequired,
  }),

  provideDates: PropTypes.bool,

  translations: PropTypes.object.isRequired,
}; // end propTypes

const defaultDate = moment(new Date());

ParkingSearchBarContainer.defaultProps = {
  handleSubmit: () => { Turbolinks.visit('/ie/parking'); },

  period: ParkingSearchBarContainer.HOURLY,

  location: {
    address: '',
    lat: 0,
    lng: 0,
  },

  dates: {
    startDate: defaultDate,
    startTime: defaultDate.clone().minute() >= 45 ? defaultDate.clone().add(1, 'hour').format('HH00') : defaultDate.clone().format('HH45'),
    endDate: defaultDate,
    endTime: defaultDate.clone().minute() >= 45 ? defaultDate.clone().add(1, 'hour').format('HH45') : defaultDate.clone().add(1, 'hour').format('HH00'),
  },

  provideDates: false
}; // end defaultProps

export default ParkingSearchBarContainer;
