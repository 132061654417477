import styled from 'styled-components';
import { FlexContainer, FlexItem } from '../../../../styledComponents/flex';

export const FiltersWrapper = styled.section`
  display: flex;
  flex-flow: column nowrap;

  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;  

  padding-top: 1em;

  background: white;
  overflow-y: scroll;
  z-index: 4;

  
`;

export const FilterCheckboxes = styled(FlexContainer)`
  background: white;
`;

export const FilterItem = styled(FlexItem)`
  background: white;
  width: 100%;
  cursor: pointer;

  &.instant-book {
    width: 100%;

    label {
      justify-content: space-between;
      align-items: center;
    }
  }

  & > label {
    display: flex;
    margin-right: 5px;
    padding: 0.4em;
    padding-left: 1em;
    cursor: pointer;

    & span {
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

export const Scroll = styled.div`
  flex: 1;
  overflow-y: scroll;
`
export const SubmitButtonWrapper = styled.div`
  display: flex;
  border-top: 1px solid HSLA(210, 7%, 95%, 1.00);

  & > button {
    flex: 1;
    margin: 1em;
  }
`;