import * as moment from 'moment';

export const formatDateTime = (dateToCompare, timeToCompare, translations) => {
  const currentDate = moment(new Date());

  const date = (
    dateToCompare.format('DDMMYYYY') === currentDate.format('DDMMYYYY')
  ) ? translations.today : dateToCompare.format('Do MMM');

  const hour = timeToCompare.slice(0, 2);
  const minutes = timeToCompare.slice(2);
  const time = `${hour}:${minutes}`;

  return `${date}, ${time}`;
}; // end formatDateTime