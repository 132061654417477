import React, { Component } from 'react';
import { connect } from 'react-redux';
import ParkingDiscoveryFilters from './ParkingDiscoveryFilter/ParkingDiscoveryFilters';
import { toggleSearchFilters } from '../../../redux/parkingDiscoveryStore/uiFlags/actions';
import { updateSortField } from '../../../redux/parkingDiscoveryStore/sort/actions';

class ParkingDiscoveryFilterContainer extends Component {
  constructor(props) {
    super(props);
  }

  handleSortChange = (e) => {
    this.props.updateSortField(e.target.value);
  }

  render() {
    return (
      <ParkingDiscoveryFilters 
        sortValue={this.props.sortBy}
        onSortChange={this.handleSortChange}
        toggleSearchFilters={this.props.toggleSearchFilters}
        activeSearchFilters={this.props.activeSearchFilters}
        anyFilterSet={this.props.anyFilterSet}
        searchResultsCount={this.props.searchResultsCount}
        address={this.props.address}
        authentication_token={this.props.authentication_token}
        lat={this.props.lat}
        lng={this.props.lng}
        translations={this.props.translations}
      />
    );
  }
}

const mapStateToProps = ({ 
  searchResults,
  uiFlags: { activeSearchFilters }, 
  searchConfig: { filters, searchBar: { location: { address, lat, lng }} },
  sort: { parkingSpaces },
  translations,
}) => {
  let anyFilterSet = false;
  if (
    Object.keys(filters.facilities).find(k => filters.facilities[k].active) ||
    filters.instantBooking ||
    (filters.propertyTypes.activePropertyType && filters.propertyTypes.activePropertyType.id != -1)
  ) {
    anyFilterSet = true;
  };

  return {
    searchResultsCount: Object.keys(searchResults).length,
    activeSearchFilters,
    anyFilterSet,
    sortBy: parkingSpaces,
    address,
    lat,
    lng,
    translations
  };
}

export default connect(mapStateToProps, { 
  toggleSearchFilters,
  updateSortField,
})(ParkingDiscoveryFilterContainer);