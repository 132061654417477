/* global google */

const createHTMLMapMarker = ({
  OverlayView = google.maps.OverlayView,
  ...args
}) => {
  class HTMLMapMarker extends OverlayView {
    constructor() {
      super();
      this.latlng = args.latlng;
      this.html = args.html;
      this.setMap(args.map);
      this.activeClass = null;
    }

    createDiv() {
      this.div = document.createElement('div');
      this.div.setAttribute('class', 'map_marker');
      this.div.style.position = 'absolute';
      if (this.html) {
        this.div.innerHTML = this.html;
      }

      google.maps.event.addDomListener(this.div, 'click', event => {
        google.maps.event.trigger(this, 'click');
      });
    }

    updateMarkerLabel(newHtml) {
      if (this.div) {
        this.div.innerHTML = newHtml;
      }
    }

    appendDivToOverlay() {
      const panes = this.getPanes();
      panes.overlayImage.appendChild(this.div);
    }

    positionDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      let offset = 25;
      if (point) {
        this.div.style.left = `${point.x - offset}px`;
        this.div.style.top = `${point.y - offset}px`;
      }
    }

    draw() {
      if (!this.div) {
        this.createDiv();
        this.appendDivToOverlay();
      }
      this.positionDiv();
    }

    remove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
        this.setMap(null)
      }
    }

    getPosition() {
      return this.latlng;
    }

    getDraggable() {
      return false;
    }

    setActiveClass(newClass) {
      if (this.div) {
        // make sure the marker comes out on top
        // this.div.setAttribute('style', 'z-index: 10000');
        
        // add the class to the div
        this.div.classList.add(newClass);

        // save reference to class name
        this.activeClass = newClass;
      }
    } // end setActive

    removeActiveClass() {
      if (this.activeClass && this.div) {
        // remove class from element
        this.div.classList.remove(this.activeClass);

        // remove reference to the classname
        this.activeClass = null;
      } // end if
    } // end setActive
  }

  return new HTMLMapMarker();
};

export default createHTMLMapMarker;
